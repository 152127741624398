import { Component, Injector, Input } from '@angular/core';
import { BanHangServiceProxy } from '@shared/service-proxies/service-proxies';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { DanhSachGoiCuocDaDangKyModalComponent } from './danh-sach-goi-cuoc-da-dang-ky-modal.component';

@Component({
    selector: 'hieu-luc-goi-cuoc',
    templateUrl: './hieu-luc-goi-cuoc.component.html'
})
export class HieuLucGoiCuocComponent extends ThemesLayoutBaseComponent {

    @Input() customStyle = 'btn btn-icon btn-clean btn-lg mr-1 w-auto px-2 text-white';
    @Input() customStyleIcon = 'far fa-calendar-alt mr-3';
    ngayHetHieuLucGoiCuoc = '';
    soNgayConLai = 0;
    loadComplete = false;
    hienTai = new Date();
    public constructor(
        injector: Injector,
        private modalService: NzModalService,
        private _banHangServiceProxy: BanHangServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._banHangServiceProxy.goiCuocDangKyCurrent().subscribe(result => {
            if(result && result.denNgay){
                this.ngayHetHieuLucGoiCuoc = moment(result.denNgay).format('DD/MM/YYYY');
                this.soNgayConLai = result.soNgayConLai;
                if(this.soNgayConLai <= 7){
                    this.customStyle = this.customStyle + ' font-red';
                    this.customStyleIcon = this.customStyleIcon + ' font-red';
                }
                else if(this.soNgayConLai > 7 && this.soNgayConLai <= 30){
                    this.customStyle = this.customStyle + ' font-yellow';
                    this.customStyleIcon = this.customStyleIcon + ' font-yellow';
                }
            }
            else
            {
                this.ngayHetHieuLucGoiCuoc = "GÓI CƯỚC HẾT HẠN";
                this.customStyleIcon = 'far fa-calendar-times mr-3 font-red';
                this.customStyle = this.customStyle + ' font-red';
            }
            this.loadComplete = true;
        });
    }

    danhSachGoiCuocDaDangKy(){
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        const modal = this.modalService.create({
            nzTitle: 'Danh sách gói cước đã đăng ký ' + icon,
            nzContent: DanhSachGoiCuocDaDangKyModalComponent,
            nzFooter: null,
            nzWidth: "60%"
        });
    }
}
