import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'api-huyen-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn quận/huyện"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => APIHuyenComboComponent),
        multi: true
    }]
})

export class APIHuyenComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {

    constructor(private _service: UtilityServiceProxy) {
        super();
    }
    notData = false;

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        abp.event.on('huyen-combo-get-by-tinh', (maTinh) => {
            this.getDataSourceFromServer(maTinh);
        });
    }

    handlerTinhComboChange(maTinh) {
        this.getDataSourceFromServer(maTinh);
        let f = this.optionList.find(it => {
            return it.value === this._value;
        });
        if (AppUtilityService.isNullOrEmpty(f)) {
            this._value = null;
            this.onChangeValue(null);
        }
    }

    getDataSourceFromServer(maTinh) {
        this.notData = false;
        if (AppUtilityService.isNullOrEmpty(maTinh)) {
            this.setListOfOption([]);
            this.notData = true;
            return;
        }
        const key = 'huyen-combo-data-' + maTinh;
        const cache = sessionStorage.getItem(key);
        if (cache && JSON.parse(cache) && JSON.parse(cache).length > 0) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT Id, MaQuanHuyen, TenQuanHuyen
        FROM API_DMHuyen
        where IsDeleted = 0 and MaTinhThanh = @MaTinh`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                MaTinh: maTinh
            }
        };

        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.MaQuanHuyen,
                    displayText: it.TenQuanHuyen,
                    fts: AppUtilityService.getFullTextSearch(it.TenQuanHuyen)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }


    getItemSelected(maQuanHuyen){
        let f = this.optionList.find(it => {
            return it.value === maQuanHuyen;
        });
        return f;
    }

}
