import {
    Component, OnInit, ElementRef, EventEmitter, Injector, Output, ViewChild,
    KeyValueDiffer, Input, AfterViewInit
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule  } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import { delay, finalize } from 'rxjs/operators';
import {
    DynamicFormPageComponent,
    OrdFormItem
} from '@main_pages/_base/form-custom/dynamic-form/dynamic-form-page.component';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import * as $ from 'jquery';
import { DragDrop } from '@angular/cdk/drag-drop';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ResponseCodeStatus } from '@shared/service-proxies/service-proxies';
import { AppMainComponentBase } from '@main_pages/_base/app-main.component';
@Component({
    selector: 'cau-hinh-lop-cho-nam-hoc-modal',
    templateUrl: './cau-hinh-lop-cho-nam-hoc-modal.component.html'
})

export class CauHinhLopChoNamHocModalComponent extends AppMainComponentBase implements OnInit{
    baseUrlService = '/api/services/app/DMLopHoc/';
    namhoc = this.appSession.user.namHoc;
    isDungDuLieuCu = true;
    validateForm: FormGroup;
    get lopHocArr(): FormArray {
        return this.validateForm.get('lopHocArr') as FormArray;
    }
    constructor(
        private _httpClient: HttpClient,
        injector: Injector,
        private modal: NzModalRef,
        private fb: FormBuilder,
        private dragDrop: DragDrop
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            lopHocArr: this.fb.array([]),
            tenantId: [this.appSession.tenantId] //phải để ở đây để lưu được tenantId
        });
        this.getDanhSachLopHocNamTruoc();
    }

    getDanhSachLopHocNamTruoc(): void {
        abp.ui.setBusy();
        const url = AppConsts.remoteServiceBaseUrl + this.baseUrlService + 'GetDanhSachLopHocNamTruoc';
        this._httpClient
            .post<any>(url,null)
            .pipe(
                finalize(() => {
                abp.ui.clearBusy();
                })
            )
            .subscribe(response => {
                let data = response.result;
                if(data && data.length > 0){
                    data.forEach(item => {
                        this.addLopHoc(item);
                    })
                }
            });

    }

    addLopHoc(item?:any) {
        if(item == undefined || item == null){
            item = {};
        }
        this.lopHocArr.insert(this.lopHocArr.length,this.fb.group({
            id: [item.id ?? 0],
            tenLop:[item.tenLop,Validators.required,this.noWhitespaceValidator],
            strKhoiHoc: [item.strKhoiHoc],
            khoiHocId: [item.khoiHocId, Validators.required]
        }))
    }

    removeLopHoc(idx:number){
        this.lopHocArr.removeAt(idx);
    }

    checkValidateForm(){
        for (const key in this.validateForm.controls) {
            if(key === 'lopHocArr'){
                let lopHocArr = this.validateForm.controls[key] as FormArray;
                for(const index in lopHocArr.controls){
                    let group = lopHocArr.controls[index] as FormGroup;
                    for(const prop in group.controls){
                        group.controls[prop].markAsDirty();
                        group.controls[prop].updateValueAndValidity();
                    }
                }
            }
            else{
                this.validateForm.controls[key].markAsDirty();
                this.validateForm.controls[key].updateValueAndValidity();
            }
        }
        if (this.validateForm.invalid) {
            abp.notify.error('Vui lòng điền đầy đủ thông tin!');
            return false;
        }

        return this.validateForm.valid;
    }

    save(): void {
        let valiate = this.checkValidateForm();
        if (valiate == true) {
            let data = this.validateForm.value;
            abp.ui.setBusy();
            const url = AppConsts.remoteServiceBaseUrl + this.baseUrlService + 'CapNhatLopHocChoNamMoi';
            this._httpClient
                .post<any>(url, data.lopHocArr)
                .pipe(finalize(() => {
                    abp.ui.clearBusy();
                }))
                .subscribe(response => {
                    if(response.result.code == ResponseCodeStatus.ThanhCong){
                        abp.notify.success('Lưu thành công');
                        this.modal.close(1);
                    }
                    else
                    {
                        abp.notify.error(response.result.message);
                    }
                });
        }
    }

    close(): void {
        this.modal.destroy();
    }

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    ngAfterViewInit(): void {
        this.fullScreenClick();
    }
}
