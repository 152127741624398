<a href="javascript:;" class="siderbar-left-top" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="menu" >
    <em class="fas fa-th"></em>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu" >
    <ul nz-menu nz-row class="siderbar-chucnang">
        <!-- <ng-container *ngFor="let menu of menu.items">
            <ng-container *ngIf="showMenuItem(menu)">
                <a class="kt-app kt-menuitem"  [routerLink]="menu.route">
                    <div class="kt-items">
                        <div class="kt-app_icon" style="background-image: url('{{menu.linkImage}}')"></div>
                        <div class="kt-caption">{{menu.name | localize }}</div>
                    </div>
                </a>
            </ng-container>
        </ng-container> -->
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="/app/main/home" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="background-image: url('../../../assets/common/images/image-icon/web-browser.svg')"></div>
                <div class="kt-caption">Danh sách chức năng</div>
            </a>
        </li>
        <ng-container *ngFor="let menu of menus.items" >
            <ng-container *ngIf="showMenuItem(menu)">

                <li nz-menu-item nz-col [nzSpan]="12">
                    <a class="siderbar-chucnang-item" [routerLink]="menu.route">
                        <div class="kt-app_icon" style="background-image: url('{{menu.linkImage}}')"></div>
                        <div class="kt-caption">{{menu.name | localize }}</div>
                    </a>
                </li>
            </ng-container>
        </ng-container>
        <!-- <li nz-menu-item nz-col [nzSpan]="12">
            <a href="/app/main/home" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/house.svg')"></div>
                <div class="kt-caption">Home</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="background-image: url('../../../assets/common/images/image-icon/setting.svg')"></div>
                <div class="kt-caption">Cấu hình thông tin</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="/app/pages/suc-khoe-hoc-sinh/danh-sach-hoc-sinh" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/healthcare.svg')"></div>
                <div class="kt-caption">Sức khỏe học sinh</div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="/app/pages/quan-ly-can-bo/can-bo-nhan-vien" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/team.svg')"></div>
                <div class="kt-caption">Cán bộ nhân viên</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/contract.svg')"></div>
                <div class="kt-caption">Bảo hiểm y tế</div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/drugs.svg')"></div>
                <div class="kt-caption">Danh mục thuốc</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/computer.svg')"></div>
                <div class="kt-caption">Danh mục trang thiết bị</div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/bill.svg')"></div>
                <div class="kt-caption">Danh mục thu chi</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/virus.svg')"></div>
                <div class="kt-caption">Bệnh truyền nhiễm</div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/soup.svg')"></div>
                <div class="kt-caption">Vệ sinh an toàn thực phẩm</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/school.svg')"></div>
                <div class="kt-caption">Vệ sinh học đường</div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/interview.svg')"></div>
                <div class="kt-caption">Góc tư vấn</div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/speech-bubble.svg')"></div>
                <div class="kt-caption">Theo dõi chế độ CSKH </div>
            </a>
        </li>

        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/online-support.svg')"></div>
                <div class="kt-caption">Hỗ trợ trực tuyến </div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/support.svg')"></div>
                <div class="kt-caption"> Phản ánh dịch vụ chăm sóc khác hàng  </div>
            </a>
        </li>
        <li nz-menu-item nz-col [nzSpan]="12">
            <a href="" class="siderbar-chucnang-item">
                <div class="kt-app_icon" style="        background-image: url('../../../assets/common/images/image-icon/account.svg')"></div>
                <div class="kt-caption"> Quản trị  </div>
            </a>
        </li> -->
    </ul>
</nz-dropdown-menu>
