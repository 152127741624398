
<div class="orda-menu-item">
        <ul nz-menu nzMode="horizontal">
            <ng-container *ngFor="let menuItem of MenuItemData">
                <ng-container *ngIf="menuItem.items.length <=0 && ( menuItem.route !='' || menuItem.route != null )">
                    <li nz-menu-item routerLinkActive="active">
                        <a [routerLink]="menuItem.route" [queryParams]="menuItem.parameters" routerLinkActive="active">
                            <em nz-icon nzType="mail"></em>
                            {{menuItem.name | localize}}
                        </a>
                    </li>
                </ng-container>

                <ng-container *ngIf="menuItem.route == '' || menuItem.route == null ">
                    <ng-container *ngFor="let menuItemChild of menuItem.items">
                        <li nz-menu-item routerLinkActive="active">
                            <a [routerLink]="menuItemChild.route" [queryParams]="menuItemChild.parameters" routerLinkActive="active">
                                <em nz-icon nzType="mail"></em>
                                {{menuItemChild.name | localize}}
                            </a>
                        </li>

                        <ng-container *ngIf="menuItemChild.items.length > 0">
                            <li nz-submenu nzTitle="{{menuItemChild.name | localize}}">
                            <ul>
                                <ng-container *ngFor="let child of menuItemChild.items">
                                <li nz-menu-item routerLinkActive="active">
                                    <a [routerLink]="menuItem.route" [queryParams]="menuItem.parameters" routerLinkActive="active">
                                    <em nz-icon nzType="mail"></em>
                                    {{child.name | localize}}
                                    </a>
                                </li>
                                </ng-container>
                            </ul>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ul>
</div>

<!-- <nz-tabset style="height:220px;"
    [nzTabPosition]="nzTabPosition"
    [(nzSelectedIndex)]="selectedIndex"
    (nzSelectChange)="log([$event])">
        <ng-container *ngFor="let menuItem of MenuItemData">
            <nz-tab
                [nzTitle]="menuItem.name | localize"
                [nzDisabled]="menuItem.disabled"
                (nzSelect)="log(['select', menuItem])"
                (nzClick)="clickRouter(menuItem.route)"
                (nzContextmenu)="log(['contextmenu', menuItem])"
                (nzDeselect)="log(['deselect', menuItem])"
                (nz-tab-link)="menuItem.route"
                ></nz-tab>

                <ng-container *ngIf="menuItem.items.length > 0" >
                    <ng-container *ngFor="let menuItemchild of menuItem.items">
                        <nz-tab
                        [nzTitle]="menuItemchild.name | localize"
                        [nzDisabled]="menuItemchild.disabled"
                        (nzSelect)="log(['select', menuItemchild])"
                        (nzClick)="log(['click', menuItemchild])"
                        (nzContextmenu)="log(['contextmenu', menuItemchild])"
                        (nzDeselect)="log(['deselect', menuItemchild])"
                        (nz-tab-link)="menuItemchild.route"
                        ></nz-tab>
                    </ng-container>

                </ng-container>
        </ng-container>
</nz-tabset> -->
