import {Input,
    Component, forwardRef, OnInit, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { Output } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({
    selector: 'thuoc-hansudung-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DMThuocHanSuDungComboComponent),
        multi: true
    }]
})

export class DMThuocHanSuDungComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _httpClient: HttpClient,
                private _service: UtilityServiceProxy,
                private appSession: AppSessionService) {
        super();
    }
    @Input() thuocId;
    @Output() getInfoThuoc = new EventEmitter();

    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer(this.thuocId);
    }

    onChangeValue(v) {
        let a = this.optionList.find(x => x.value === v);
        this.getInfoThuoc.emit(a);
        this.onChange(v);
    }

    handlerThuocComboChange(thuocId) {
        this.getDataSourceFromServer(thuocId);
    }

    getDataSourceFromServer(thuocId) {
        let tenantId = this.appSession.tenant.id;
        let query = `select thuocId, hanSuDung,soLuong from  KHO_ThuocTaiTruong
                    where KHO_ThuocTaiTruong.tenantId = @tenantId and thuocId = @thuocId `;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                tenantId: tenantId,
                thuocId: thuocId
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: moment(it.hanSuDung).format('DD/MM/YYYY'),
                    displayText: moment(it.hanSuDung).format('DD/MM/YYYY'),
                    soLuong:it.soLuong,
                    fts: AppUtilityService.getFullTextSearch(moment(it.hanSuDung).format('DD/MM/YYYY'))
                });
            });
            this.setListOfOption(lst);
        });
    }
}
