import { Router } from '@angular/router';
import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'tinhtrangchisosuckhoe-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn tình trạng sức khỏe"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TinhTrangChiSoSucKhoeComboComponent),
        multi: true
    }]
})

export class TinhTrangChiSoSucKhoeComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    @Input() IsChangeTrigger :boolean= false;
    constructor(private _service: UtilityServiceProxy,private router:Router,
        private _httpClient: HttpClient) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        const key = 'tinhtrangchisosuckhoe-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }

        abp.ui.setBusy();
        let url = AppConsts.remoteServiceBaseUrl + "/api/services/app/CommonEnum/GetListEnumTinhTrangChiSoSucKhoe";
        this._httpClient.get(url)
        .pipe(finalize(() => {
            abp.ui.clearBusy();
        })).subscribe((response) => {
            let ret =response as any;
            if (ret) {
                let lst = _.map(ret.result, (it) => {
                    return Object.assign({}, {
                        value: it.id,
                        displayText: it.name,
                        fts: AppUtilityService.getFullTextSearch(it.name)
                    });
                });
                sessionStorage.setItem(key, JSON.stringify(lst));
                this.setListOfOption(lst);
            }
        });

    }



}
