import {Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'api-xa-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn xã/phường"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='notData || _isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => APIXaComboComponent),
        multi: true
    }]
})

export class APIXaComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    notData = true;

    constructor(private _service: UtilityServiceProxy) {
        super();
        this._isDisabled = false;
    }

    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        abp.event.on('xa-combo-get-by-huyen', (maHuyen) => {
            this.getDataSourceFromServer(maHuyen);
        });
    }

    handlerHuyenComboChange(maHuyen) {
        this.getDataSourceFromServer(maHuyen);
        let f = this.optionList.find(it => {
            return it.value === this._value;
        });
        if (AppUtilityService.isNullOrEmpty(f)) {
            this._value = null;
            this.onChangeValue(null);
        }
    }

    getDataSourceFromServer(maHuyen) {
        this.notData = false;
        if (AppUtilityService.isNullOrEmpty(maHuyen)) {
            this.setListOfOption([]);
            this.notData = true;
            return;
        }
        const key = 'api-xa-combo-data-' + maHuyen;
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT maTinhThanh,tenTinhThanh,maQuanHuyen,tenQuanHuyen,maPhuongXa,tenPhuongXa
         FROM API_DMXa
         where  maQuanHuyen = @MaHuyen`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                MaHuyen: maHuyen
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.maPhuongXa,
                    displayText: it.tenPhuongXa,
                    fts: AppUtilityService.getFullTextSearch(it.tenPhuongXa)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

    getItemSelected(maXa){
        let f = this.optionList.find(it => {
            return it.value === maXa;
        });
        return f;
    }
}
