import { Component, forwardRef, OnInit, Output, Input, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'huyen-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='notData || _isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event);"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => HuyenComboComponent),
        multi: true
    }]
})

export class HuyenComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    notData = false;

    constructor(private _service: UtilityServiceProxy) {
        super();
        this._isDisabled = false;
    }

    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        abp.event.on('huyen-combo-get-by-tinh', (maTinh) => {
            this.getDataSourceFromServer(maTinh);
        });
    }

    handlerTinhComboChange(maTinh) {
        this.getDataSourceFromServer(maTinh);
        setTimeout(()=>{                       
            let f = this.optionList.find(it => {
                return it.value === this._value;
            });
            if (AppUtilityService.isNullOrEmpty(f)) {
                this._value = null;
                this.onChangeValue(null);
            }
        }, 500);
    }

    // setTriggerHuyenChange(maHuyen){
    //     abp.event.trigger('huyen-combo@huyenChange',maHuyen);
    // }

    getDataSourceFromServer(maTinh) {
        this.notData = false;
        if (AppUtilityService.isNullOrEmpty(maTinh)) {
            this.setListOfOption([]);
            this.notData = true;
            return;
        }
        const key = 'huyen-combo-data-' + maTinh;
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT maTinh,tenTinh,maHuyen,tenHuyen
        FROM dm_huyen
        where IsDeleted = 0 and MaTinh = @MaTinh`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                MaTinh: maTinh
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.maHuyen,
                    displayText: it.tenHuyen,
                    fts: AppUtilityService.getFullTextSearch(it.tenHuyen)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
            
        });
    }

    getItemSelected(maHuyen){
        let f = this.optionList.find(it => {
            return it.value === maHuyen;
        });
        return f;
    }
}
