<div dropdown class="dropdown dropdown-language" aria-haspopup="true" *ngIf="languages.length > 0" [dropup]="isDropup">
    <div dropdownToggle class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false" nz-popover nzPopoverContent="{{currentLanguage.displayName}}">
        <div [class]="customStyle">
            <em [class]="currentLanguage.icon"></em>
        </div>
    </div>
    <div *dropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right" [attr.aria-label]="l('Languages')">
        <perfect-scrollbar>
        <ul class="navi navi-hover py-4">
            <li class="navi-item" *ngFor="let language of languages">
                <a href="javascript:;" (click)="changeLanguage(language.name)" class="navi-link">
                    <span class="symbol symbol-20 mr-3">
                        <em [class]="language.icon"></em>
                    </span>
                    <span class="navi-text">
                        {{language.displayName}}
                    </span>
                </a>
            </li>
        </ul>
    </perfect-scrollbar>
    </div>
</div>
    <!-- <div class="kt-header__topbar-item">
        <a href="javascript:void(0);" style="float: left;padding: 10px 5px 0px 5px;text-decoration: none; cursor: pointer" title="Tiếng việt" (click)="changeLanguage('vi')">
            <img src="../../../assets/common/images/vietnam.jpg" />
        </a>
        <a href="javascript:void(0);" style="float: left;padding: 10px 5px 0px 5px;text-decoration: none; cursor: pointer" title="English" (click)="changeLanguage('en')">
            <img src="../../../assets/common/images/usa.jpg" />
        </a>
    </div> -->
