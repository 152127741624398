import { Component, OnInit, Injector, ViewEncapsulation, Output, ViewChild, ElementRef } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppMainComponentBase } from '@main_pages/_base/app-main.component';
import { Router } from '@angular/router';


@Component({
    selector: 'app-disconnected',
    templateUrl: './disconnected.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})


export class DisconnectedComponent extends AppMainComponentBase implements OnInit {


    constructor(injector: Injector,
        private route:Router
    ) {
        super(injector);
    }

    ngOnInit(): void {

    }

    homePage(){
        this.route.navigate(['app/main/home']);
    }


}


