import { BaoHiemTeMenuItem, HostBaoCao, HostKiemSoatCovid19, KiemSoatCovidMenuItem, KinhDoanh, ThongTinHocSinhMenuItem, ThongTinLienLacMenuItem, YteHostMenuItem, SoCapCuuMenuItem, XemThongTinThamKhaoMenuItem, ApiSmasMenuItem} from './../../../../app-main/menu/application-menu-item';
import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';
import { AdministrationMenuItem } from '@main_pages/menu/admin-menu-item';
import { CauHinhThongTinMenuItem, SucKhoeHocSinhMenuItem, CanBoNhanVienMenuItem, TrangThietBiYTEMenuItem, DanhMucThuocMenuItem, VeSinhATTPMenuItem, VeSinhHocDuongMenuItem, DanhMucHostMenuItem, ApiHoSoSucKhoeMenuItem, QuanLyTaiKhoan, TruongHoc, DieuHanh, BaoCaoMenuItem,ThongTinThamKhaoMenuItem, KhaiThongTinBHYTMenuItem, CanDoTheLucMenuItem } from '../../../../app-main/menu/application-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'fas fa-tachometer-alt', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fas fa-tachometer-alt', '../../../assets/common/images/image-icon/house.svg', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'fas fa-handshake', '../../../assets/common/images/image-icon/agenda.svg',  '/app/admin/tenants'),
            //new AppMenuItem('Editions', 'Pages.Editions', 'fas fa-toggle-on', '../../../assets/common/images/image-icon/stopwatch.svg', '/app/admin/editions'),
            ...DieuHanh,
            ...KinhDoanh,
            ...SoCapCuuMenuItem,
            ...CanDoTheLucMenuItem,
            ...YteHostMenuItem,
            ...HostBaoCao,
            ...ThongTinLienLacMenuItem,
            ...TruongHoc,
            ...ApiHoSoSucKhoeMenuItem,
            ...ApiSmasMenuItem,
            ...DanhMucHostMenuItem,
            ...ThongTinThamKhaoMenuItem,
            ...SucKhoeHocSinhMenuItem,
            ...CanBoNhanVienMenuItem,
            ...BaoHiemTeMenuItem,
            ...DanhMucThuocMenuItem,
            ...TrangThietBiYTEMenuItem,
            ...VeSinhATTPMenuItem,
            ...VeSinhHocDuongMenuItem,
            ...BaoCaoMenuItem,
            ...KiemSoatCovidMenuItem,
            ...ThongTinHocSinhMenuItem,
            ...KhaiThongTinBHYTMenuItem,
            ...CauHinhThongTinMenuItem,
            ...XemThongTinThamKhaoMenuItem,
            ...QuanLyTaiKhoan,
            ...AdministrationMenuItem,
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
