import { Component, forwardRef, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'api-truong-hoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn trường học"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='notData || _isDisabled'
                   (nzFocus)="onFocus($event)"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event);truongHocOnChange.emit($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ApiTruongHocComboComponent),
        multi: true
    }]
})

export class ApiTruongHocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    notData = false;

    constructor(private _service: UtilityServiceProxy) {
        super();
        this._isDisabled = true;
    }
     @Output() truongHocOnChange = new EventEmitter();
    // @Output() idValue : EventEmitter<string> =new EventEmitter<string>();
    // @Output() strValue : EventEmitter<string> =new EventEmitter<string>();
    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        abp.event.on('api-truong-hoc-combo-get-by-tinh', (maTinh) => {
            this.getDataSourceFromServer(maTinh);
        });
    }

    handlerTinhComboChange(maTinh) {

        if (AppUtilityService.isNullOrEmpty(maTinh)) {
            this._isDisabled = true;
        }
        else{
            this._isDisabled = false;
        }
        this.getDataSourceFromServer(maTinh);
        let f = this.optionList.find(it => {
            return it.value === this._value;
        });
        if (AppUtilityService.isNullOrEmpty(f)) {
            this._value = null;
            this.onChangeValue(null);
        }
    }

    getDataSourceFromServer(maTinh) {
        this.notData = false;
        if (AppUtilityService.isNullOrEmpty(maTinh)) {
            this.setListOfOption([]);
            this.notData = true;
            return;
        }
        const key = 'api-truong-hoc-combo-data-' + maTinh;
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT maTthanh,maTruongHoc,tenTruongHoc
        FROM API_TruongHoc
        where IsDeleted = 0 and maTthanh = @MaTinh and IsCreate = 0`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                MaTinh: maTinh
            }
        };

        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.maTruongHoc,
                    displayText: it.tenTruongHoc,
                    fts: AppUtilityService.getFullTextSearch(it.tenTruongHoc)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

    getItemSelected(maTruongHoc){
        let f = this.optionList.find(it => {
            return it.value === maTruongHoc;
        });
        return f;
    }
}
