import { AppSessionService } from './../../shared/common/session/app-session.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ROLE_LEVEL } from '@shared/service-proxies/service-proxies';
import {AppMenuItem} from '@app/shared/layout/nav/app-menu-item';

//#region TRƯỜNG HỌC MENU

//Sức khỏe học sinh
export const SucKhoeHocSinhMenuItem = [
    new AppMenuItem('SKHS', 'Pages.SucKhoeHocSinh', 'fas fa-heartbeat','../../../assets/common/images/image-icon/healthcare.svg', '/app/pages/suc-khoe-hoc-sinh/cap-nhat-hang-ngay', [], [
        new AppMenuItem('SKHS_CapNhatHangNgay', 'Pages.SucKhoeHocSinh.CapNhatHangNgay', 'far fa-eye','', '/app/pages/suc-khoe-hoc-sinh/cap-nhat-hang-ngay'),
        new AppMenuItem('SKHS_KetQuaKhamDinhKy', 'Pages.SucKhoeHocSinh.KetQuaKhamDinhKy', 'fas fa-stethoscope', '','/app/pages/suc-khoe-hoc-sinh/cap-nhat-chi-so-suc-khoe'),
        new AppMenuItem('SKHS_KhamChuyenKhoa', 'Pages.SucKhoeHocSinh.KhamChuyenKhoa', 'fas fa-user-md', '','/app/pages/suc-khoe-hoc-sinh/kham-chuyen-khoa'),
        new AppMenuItem('SKHS_HoSoSucKhoe', 'Pages.SucKhoeHocSinh.HoSoSucKhoe', 'fas fa-file-invoice', '','/app/pages/suc-khoe-hoc-sinh/ho-so-suc-khoe'),
        new AppMenuItem('SKHS_SoTheoDoiTongHop', 'Pages.SucKhoeHocSinh.SoTheoDoiTongHop', 'fas fa-file-archive', '','/app/pages/suc-khoe-hoc-sinh/so-theo-doi-tong-hop')
    ])
];

//Cán bộ nhân viên
export const CanBoNhanVienMenuItem = [
    new AppMenuItem('CanBoNhanVien', 'Pages.CanBoNhanVien', 'fas fa-user-tie', '../../../assets/common/images/image-icon/team.svg', '/app/pages/quan-ly-can-bo/theo-doi-suc-khoe-can-bo', [], [
        new AppMenuItem('CanBoNhanVien_TheoDoiBatThuong', 'Pages.CanBoNhanVien.TheoDoiBatThuong', 'fas fa-hand-holding-heart', '', '/app/pages/quan-ly-can-bo/theo-doi-suc-khoe-can-bo'),
        new AppMenuItem('CanBoNhanVien_TheoDoiTheLuc', 'Pages.CanBoNhanVien.TheoDoiTheLuc', 'fas fa-stethoscope', '', '/app/pages/quan-ly-can-bo/theo-doi-the-luc'),
    ])
];

//Bảo hiểm y tế
export const BaoHiemTeMenuItem = [
    new AppMenuItem('BaoHiemYTe', 'Pages.BaoHiemYTe', 'fa fa-medkit', '../../../assets/common/images/image-icon/contract.svg', '/app/pages/quan-ly-bao-hiem-y-te/bao-hiem-yte'),
];

//Quản lý thuốc
export const DanhMucThuocMenuItem = [
    new AppMenuItem('DanhMucThuoc', 'Pages.DanhMucThuoc', 'fas fa-spray-can', '../../../assets/common/images/image-icon/drugs.svg', '/app/pages/danh-muc-thuoc/theo-doi-thuoc-tai-truong', [], [
        new AppMenuItem('DanhMucThuoc_TheoDoiThuocTaiTruong', 'Pages.DanhMucThuoc.TheoDoiThuocTaiTruong', 'fas fa-search-location', '','/app/pages/danh-muc-thuoc/theo-doi-thuoc-tai-truong'),
        new AppMenuItem('DanhMucThuoc_TaiTruong', 'Pages.DanhMucThuoc.TaiTruong', 'fas fa-layer-group', '', '/app/pages/danh-muc-thuoc/nhapthuoc'),
    ])
];

//Trang thiết bị y tế
export const TrangThietBiYTEMenuItem = [
    new AppMenuItem('TrangThietBiYTe', 'Pages.TrangThietBiYTe', 'fas fa-laptop', '../../../assets/common/images/image-icon/computer.svg', '/app/pages/danh-muc-thiet-bi/dm-thiet-bi', [], [
    ])
];

//Vệ sinh an toàn thực phẩm
export const VeSinhATTPMenuItem = [
    new AppMenuItem('VeSinhATTP', 'Pages.VeSinhATTP', 'fas fa-cookie', '../../../assets/common/images/image-icon/soup.svg', '/app/pages/quan-ly-attp/ve-sinh-attp', [], [
        new AppMenuItem('VeSinhATTP_VSATTP', 'Pages.VeSinhATTP.LuuMauThucAn', 'fas fa-book', '', '/app/pages/quan-ly-attp/ve-sinh-attp'),
        new AppMenuItem('VeSinhATTP_LuuMauThucAn', 'Pages.VeSinhATTP.VSATTP', 'fas fa-bong', '', '/app/pages/quan-ly-attp/luu-mau-thuc-an'),
        new AppMenuItem('VeSinhATTP_DanhSachNhaCungCap', 'Pages.VeSinhATTP.DanhSachNhaCungCap', 'fas fa-store', '', '/app/pages/quan-ly-attp/qlattp-nha-cung-cap'),
        new AppMenuItem('NguyenLieuThucPham', 'Pages.DanhMucChung.NguyenLieuThucPham', 'fa fa-cubes', '', '/app/pages/danh-muc/nguyen-lieu-thuc-pham'),
    ])
];

//Vệ sinh học đường
export const VeSinhHocDuongMenuItem = [
    new AppMenuItem('VeSinhHocDuong', 'Pages.VeSinhHocDuong', 'fas fa-person-booth', '../../../assets/common/images/image-icon/hand-sanitizer.svg', '/app/pages/danh-muc-ve-sinh-hoc-duong/ve-sinh-hoc-duong', [], [
        new AppMenuItem('SoTheoDoi', 'Pages.VeSinhHocDuong', 'fas fa-book', '', '/app/pages/danh-muc-ve-sinh-hoc-duong/ve-sinh-hoc-duong'),
        new AppMenuItem('KhuVucTruongHoc', 'Pages.VeSinhHocDuong', 'fa fa-object-group', '', '/app/pages/danh-muc/khu-vuc-truong-hoc'),
    ]),
];

//Báo cáo
export const BaoCaoMenuItem = [
    new AppMenuItem('BaoCao', 'Pages.BaoCao', 'fas fa-book-medical', '../../../assets/common/images/image-icon/report.svg', '/app/pages/bao-cao/lap-ke-hoach-attp', [], [
        new AppMenuItem('BaoCao_LapKeHoachCongTacYTeHocDuong', 'Pages.BaoCao.LapKeHoachCongTacATTP', 'far fa-file-alt', '', '/app/pages/bao-cao/lap-ke-hoach-attp'),
        new AppMenuItem('BaoCao_DanhGiaCongTacYTeHocDuong', 'Pages.BaoCao.DanhGiaCongTacYTeHocDuong', 'far fa-file-image', '', '/app/pages/bao-cao/danh-gia-ctyt-pl03'),
        new AppMenuItem('BaoCao_BaoCaoCongTacYTeHocDuong', 'Pages.BaoCao.BaoCaoCongTacYTeHocDuong', 'fas fa-file-medical-alt', '', '/app/pages/bao-cao/bao-cao-yte-pl02'),
    ])
];

//Kiếm soát dịch bệnh covid-19
export const KiemSoatCovidMenuItem = [
    new AppMenuItem('KiemSoatCovid', 'Pages.KiemSoatCovid', 'fas fa-shield-virus', '../../../assets/common/images/image-icon/prevention.svg', '/app/pages/quan-ly-khai-bao-y-te/khai-bao-y-te/chi-tiet/0', [], [
        new AppMenuItem('KiemSoatCovid_KhaiBaoYTe', 'Pages.KiemSoatCovid.KhaiBaoYTe', 'fas fa-medkit', '', '/app/pages/quan-ly-khai-bao-y-te/khai-bao-y-te/chi-tiet/0'),
        new AppMenuItem('KiemSoatCovid_DanhSachKhaiBao', 'Pages.KiemSoatCovid.DanhSachKhaiBao', 'fas fa-file-signature', '', '/app/pages/kiem-soat-covid/danh-sach-khai-bao'),
        new AppMenuItem('KiemSoatCovid_TinhHinhKhaiBao', 'Pages.KiemSoatCovid.TinhHinhKhaiBao', 'fas fa-notes-medical', '', '/app/pages/kiem-soat-covid/tinh-hinh-khai-bao'),
        new AppMenuItem('KiemSoatCovid_NguyCoLayNhiem', 'Pages.KiemSoatCovid.NguyCoLayNhiem', 'fas fa-user-shield', '', '/app/pages/kiem-soat-covid/nguy-co-lay-nhiem'),
        new AppMenuItem('KiemSoatCovid_CaiDat', 'Pages.KiemSoatCovid.CaiDat', 'fas fa-cogs', '', '/app/pages/kiem-soat-covid/noi-dung-thong-bao'),
    ])
];

//Cấu hình thông tin
export const CauHinhThongTinMenuItem = [
    new AppMenuItem('CauHinhThongTin', 'Pages.CauHinhThongTin', 'fa fa-cog', '../../../assets/common/images/image-icon/setting.svg', '/app/pages/quan-ly-can-bo/can-bo-nhan-vien', [], [
        // new AppMenuItem('CauHinhThongTin_DiemTruong', 'Pages.CauHinhThongTin.DiemTruong', 'fas fa-map-marked-alt','', '/app/pages/cau-hinh-thong-tin/diem-truong'),
        new AppMenuItem('CauHinhThongTin_DongBoDuLieuGiaoDuc', 'Pages.CauHinhThongTin.DongBoDuLieuGiaoDuc', 'fas fa-sync-alt','', '/app/pages/cau-hinh-thong-tin/dong-bo-du-lieu-giao-duc'),
        new AppMenuItem('CauHinhThongTin_DongBoDuLieuGiaoDucSMAS', 'Pages.CauHinhThongTin.DongBoDuLieuGiaoDucSMAS', 'fas fa-sync-alt','', '/app/pages/cau-hinh-thong-tin/dong-bo-du-lieu-smas'),
        new AppMenuItem('CauHinhThongTin_DanhSachCanBo', 'Pages.CauHinhThongTin.DanhSachCanBo', 'fas fa-user-plus', '', '/app/pages/quan-ly-can-bo/can-bo-nhan-vien'),
        new AppMenuItem('CauHinhThongTin_LopHoc', 'Pages.CauHinhThongTin.LopHoc', 'fas fa-home', '', '/app/pages/cau-hinh-thong-tin/lop-hoc/false'),
        new AppMenuItem('CauHinhThongTin_DanhSachHocSinh', 'Pages.CauHinhThongTin.DanhSachHocSinh', 'fas fa-user-graduate', '','/app/pages/suc-khoe-hoc-sinh/danh-sach-hoc-sinh'),
        new AppMenuItem('CauHinhThongTin_LenLop', 'Pages.SucKhoeHocSinh.LenLop', 'fas fa-arrow-circle-up', '','/app/pages/cau-hinh-thong-tin/len-lop'),
        new AppMenuItem('CauHinhThongTin_ChuyenHoc', 'Pages.CauHinhThongTin.ChuyenHoc', 'fas fa-retweet','', '/app/pages/cau-hinh-thong-tin/chuyen-hoc'),
        new AppMenuItem('CauHinhThongTin_TaiKhoanPhuHuynh', 'Pages.CauHinhThongTin.TaiKhoanPhuHuynh', 'fas fa-address-book', '','/app/pages/cau-hinh-thong-tin/tai-khoan-phu-huynh'),
        new AppMenuItem('CauHinhThongTin_TaiKhoanBacSiChuyenKhoa', 'Pages.CauHinhThongTin.TaiKhoanBacSiChuyenKhoa', 'fas fa-user-md', '','/app/pages/cau-hinh-thong-tin/tai-khoan-bac-si-chuyen-khoa'),
        new AppMenuItem('CauHinhThongTin_LienThongHoSoSucKhoe', 'Pages.CauHinhThongTin.LienThongHoSoSucKhoe', 'far fa-calendar-alt', '','/app/pages/cau-hinh-thong-tin/lien-thong-ho-so-suc-khoe'),
        new AppMenuItem('CauHinhThongTin_CapNhatThongTin', 'Pages.CauHinhThongTin.CapNhatThongTin', 'fas fa-archway', '','/app/pages/cau-hinh-thong-tin/cap-nhat-thong-tin-truong-hoc'),
    ])
];

//Xem thông tin tham khảo
export const XemThongTinThamKhaoMenuItem = [
    new AppMenuItem('XemThongTinThamKhao', 'Pages.XemThongTinThamKhao', 'fas fa-book-reader', '../../../assets/common/images/image-icon/open-book.svg', '/app/pages/thong-tin-tham-khao/xem-thong-tin'),
];

//Thông tin của học sinh
export const ThongTinHocSinhMenuItem = [
    new AppMenuItem('SoSucKhoeHocSinh', 'Pages.SoSucKhoeHocSinh', 'fas fa-user-graduate', '../../../assets/common/images/image-icon/graduating-student.png', '/app/pages/thong-tin-hoc-sinh/xem-thong-tin-hoc-sinh'),
];

//Khai thông tin bảo hiểm y tế
export const KhaiThongTinBHYTMenuItem = [
    new AppMenuItem('KhaiThongTinBaoHiemYTe', 'Pages.KhaiThongTinBaoHiemYTe', 'fas fa-file-medical', '../../../assets/common/images/medical-report.svg', '/app/pages/thong-tin-bao-hiem-y-te/xem-thong-tin-bao-hiem'),
];


//#endregion

//#region  HOST MENU

// KINH DOANH
export const KinhDoanh = [
    new AppMenuItem('KinhDoanh', 'Pages.KinhDoanh', 'fas fa-file-invoice-dollar', '../../../assets/common/images/image-icon/bill.svg', '/app/pages/kinh-doanh/danh-sach-tai-khoan', [], [
        new AppMenuItem('KinhDoanh_DanhSachThueBao', 'Pages.KinhDoanh.DanhSachThueBao', 'fas fa-list', '', '/app/pages/kinh-doanh/danh-sach-tai-khoan'),
        new AppMenuItem('KinhDoanh_GoiCuoc', 'Pages.KinhDoanh.GoiCuoc', 'fas fa-list-alt', '', '/app/pages/kinh-doanh/goi-cuoc'),
        new AppMenuItem('KinhDoanh_Dashboard', 'Pages.KinhDoanh.Dashboard', 'fas fa-file-signature', '', '/app/pages/kinh-doanh/dashboard'),
        new AppMenuItem('KinhDoanh_TaiKhoanKinhDoanh', 'Pages.KinhDoanh.TaiKhoanKinhDoanh', 'far fa-id-card', '', '/app/pages/kinh-doanh/tai-khoan-kinh-doanh'),
        new AppMenuItem('KinhDoanh_LichSuGiaoTiepGoiTin', 'Pages.KinhDoanh.LichSuGiaoTiepGoiTin', 'fas fa-history', '', '/app/pages/kinh-doanh/lich-su-giao-tiep-goi-tin'),
    ])
];

//ĐIỀU HÀNH
export const DieuHanh = [
    new AppMenuItem('DieuHanh', 'Pages.DieuHanh', 'fas fa-globe-asia', '../../../assets/common/images/image-icon/shapes.svg', '/app/pages/dieu-hanh/dashboard')
];

//BẢO HIỂM Y TẾ HOST
export const YteHostMenuItem = [
    new AppMenuItem('BaoHiemYte_Host', 'Pages.Host_BaoHiemYTe', 'fas fa-file-contract', '../../../assets/common/images/image-icon/health-insurance.svg', '/app/pages/bao-hiem-y-te/danh-sach-bao-hiem-y-te')
];

//SƠ CỨU, CẤP CỨU
export const SoCapCuuMenuItem = [
    new AppMenuItem('SoCuuCapCuu', 'Pages.SoCuuCapCuu', 'fas fa-medkit', '../../../assets/common/images/image-icon/first-aid-kit.svg', '/app/pages/so-cuu-cap-cuu/so-cap-cuu-hoc-sinh',[],[
        new AppMenuItem('Host_SoCapCuu_HocSinh', 'Pages.SoCuuCapCuu.SoCuuCapCuuHocSinh', 'fas fa-notes-medical', '', '/app/pages/so-cuu-cap-cuu/so-cap-cuu-hoc-sinh'),
        new AppMenuItem('Host_SoCapCuu_CanBo', 'Pages.SoCuuCapCuu.SoCuuCapCuuCanBo', 'fas fa-briefcase-medical', '', '/app/pages/so-cuu-cap-cuu/so-cap-cuu-can-bo'),
    ])
];

export const CanDoTheLucMenuItem = [
    new AppMenuItem('DinhDuongVaTheLuc', 'Pages.DinhDuongVaTheLuc', 'fas fa-tablet', '../../../assets/common/images/image-icon/weight.svg', '/app/pages/dinh-duong-va-the-luc/the-luc-hoc-sinh',[],[
        new AppMenuItem('DinhDuongVaTheLuc_DinhDuongVaTheLucHocSinh', 'Pages.DinhDuongVaTheLuc.DinhDuongVaTheLucHocSinh', 'fas fa-user-graduate', '', '/app/pages/dinh-duong-va-the-luc/the-luc-hoc-sinh'),
        new AppMenuItem('DinhDuongVaTheLuc_DinhDuongVaTheLucCanBo', 'Pages.DinhDuongVaTheLuc.DinhDuongVaTheLucCanBo', 'fas fa-user', '', '/app/pages/dinh-duong-va-the-luc/the-luc-can-bo'),
    ])
];

//THÔNG TIN LIÊN LẠC
export const ThongTinLienLacMenuItem = [
    new AppMenuItem('LienLac', 'Pages.ThongTinLienLac', 'fas fa-address-book', '../../../assets/common/images/image-icon/phone-book.svg', '/app/pages/thong-tin-lien-lac/xem-thong-tin-lien-lac')
];
//HOST KIỂM SOÁT COVID
export const HostKiemSoatCovid19 = [
    new AppMenuItem('Host_KiemSoatCovid19', 'Pages.Host.KiemSoatCovid19', 'fas fa-virus-slash', '../../../assets/common/images/image-icon/no-virus.svg', '/app/pages/host-kiem-soat-covid/host-tinh-hinh-khai-bao', [], [
        new AppMenuItem('Host_KiemSoatCovid19_SoLieuTongQuan', 'Pages.Host.KiemSoatCovid19.SoLieuTongQuan', 'fas fa-dice-d20', '', '/app/pages/host-kiem-soat-covid/host-so-lieu-tong-quan'),
        new AppMenuItem('Host_KiemSoatCovid19_SoLieuTaiTruong', 'Pages.Host.KiemSoatCovid19.SoLieuTaiTruong', 'fas fa-notes-medical', '', '/app/pages/host-kiem-soat-covid/host-so-lieu-tai-truong'),
        new AppMenuItem('Host_KiemSoatCovid19_NguyCoLayNhiem', 'Pages.Host.KiemSoatCovid19.NguyCoLayNhiem', 'fas fa-file-signature', '', '/app/pages/host-kiem-soat-covid/host-danh-sach-khai-bao'),
    ])
];

//TRƯỜNG HỌC
export const TruongHoc = [
    new AppMenuItem('QuanLyTruongHoc', 'Pages.QuanLyTruongHoc', 'fas fa-school', '../../../assets/common/images/image-icon/school.svg', '/app/pages/he-thong/danh-sach-truong-hoc', [], [
        new AppMenuItem('DanhSachTruongHoc', 'Pages.QuanLyTruongHoc.DanhSachTruongHoc', 'fas fa-th-list', '', '/app/pages/he-thong/danh-sach-truong-hoc'),
    ])
];

//DANH MỤC HOST
export const DanhMucHostMenuItem = [
    new AppMenuItem('DanhMucHost', 'Pages.ThongTinHanhChinh', 'fas fa-sitemap', '../../../assets/common/images/image-icon/shopping-list.svg', '/app/pages/danh-muc/quoc-gia', [], [
        new AppMenuItem('QuocGia', 'Pages.ThongTinHanhChinh.QuocGia', 'fas fa-map-marked', '', '/app/pages/danh-muc/quoc-gia'),
        new AppMenuItem('TonGiao', 'Pages.ThongTinHanhChinh', 'fas fa-map-marked', '', '/app/pages/danh-muc/ton-giao'),
        new AppMenuItem('DanToc', 'Pages.ThongTinHanhChinh', 'fas fa-map-marked', '', '/app/pages/danh-muc/dan-toc'),
        new AppMenuItem('Vung', 'Pages.ThongTinHanhChinh.Vung', 'fas fa-map-marked-alt', '', '/app/pages/danh-muc/vung'),
        new AppMenuItem('Tinh', 'Pages.ThongTinHanhChinh.Tinh', 'fas fa-map-marker-alt', '', '/app/pages/danh-muc/tinh'),
        new AppMenuItem('Huyen', 'Pages.ThongTinHanhChinh.Huyen', 'fas fa-map-marker-alt', '', '/app/pages/danh-muc/huyen'),
        new AppMenuItem('Xa', 'Pages.ThongTinHanhChinh.Xa', 'fas fa-map-marker-alt', '', '/app/pages/danh-muc/xa'),
        new AppMenuItem('KhoiHoc', 'Pages.DanhMucChung_Host.KhoiHoc', 'fa fa-object-group', '', '/app/pages/danh-muc/khoi-hoc'),
        new AppMenuItem('DanhMuc_NoiKhamChuaBenhBanDau', 'Pages.DanhMucChung_Host.NoiKhamChuaBenhBanDau', 'fa fa-object-group', '', '/app/pages/danh-muc/noi-kham-chua-benh-ban-dau'),
        new AppMenuItem('DanhMuc_HFA', 'Pages.DanhMucChung_Host.HFA', 'fa fa-object-group', '', '/app/pages/danh-muc/hfa'),
        new AppMenuItem('DanhMuc_WFA', 'Pages.DanhMucChung_Host.WFA', 'fa fa-object-group', '', '/app/pages/danh-muc/wfa'),
        new AppMenuItem('DanhMuc_BFA', 'Pages.DanhMucChung_Host.BFA', 'fa fa-object-group', '', '/app/pages/danh-muc/bfa'),
        new AppMenuItem('DanhMuc_WFH', 'Pages.DanhMucChung_Host.WFH', 'fa fa-object-group', '', '/app/pages/danh-muc/wfh'),
        new AppMenuItem('DanhMuc_SSO_TRUONG_HOC', 'Pages.DanhMucChung_Host.WFH', 'fa fa-object-group', '', '/app/pages/danh-muc/sso-truong-hoc'),
    ])
];

//API CSDL EDU
// export const ApiCsdlEduMenuItem = [
//     new AppMenuItem('ApiCsdlEdu', 'Pages.ApiCsdlEdu', 'fas fa-podcast', '../../../assets/common/images/image-icon/laptop.svg', '/app/pages/api-csdl-edu/api-truong-hoc', [], [
//         new AppMenuItem('ApiCsdlEdu_TinhThanh', 'Pages.ApiCsdlEdu.TinhThanh', 'fa fa-object-group', '', '/app/pages/api-csdl-edu/api-tinh-thanh'),
//         new AppMenuItem('ApiCsdlEdu_QuanHuyen', 'Pages.ApiCsdlEdu.QuanHuyen', 'fa fa-object-group', '', '/app/pages/api-csdl-edu/api-quan-huyen'),
//         new AppMenuItem('ApiCsdlEdu_PhuongXa', 'Pages.ApiCsdlEdu.PhuongXa', 'fa fa-object-group', '','/app/pages/api-csdl-edu/api-phuong-xa'),
//         new AppMenuItem('ApiCsdlEdu_DanToc', 'Pages.ApiCsdlEdu.DanToc', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-dan-toc'),
//         new AppMenuItem('ApiCsdlEdu_TonGiao', 'Pages.ApiCsdlEdu.TonGiao', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-ton-giao'),
//         new AppMenuItem('ApiCsdlEdu_DonVi', 'Pages.ApiCsdlEdu.DonVi', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-don-vi'),
//         new AppMenuItem('ApiCsdlEdu_TruongHoc', 'Pages.ApiCsdlEdu.TruongHoc', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-truong-hoc'),
//         new AppMenuItem('ApiCsdlEdu_LopHoc', 'Pages.ApiCsdlEdu.LopHoc', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-lop-hoc'),
//         new AppMenuItem('ApiCsdlEdu_GiaoVien', 'Pages.ApiCsdlEdu.GiaoVien', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-giao-vien'),
//         new AppMenuItem('ApiCsdlEdu_HocSinh', 'Pages.ApiCsdlEdu.HocSinh', 'fa fa-object-group','', '/app/pages/api-csdl-edu/api-hoc-sinh'),
//     ])
// ];

//host báo cáo
export const HostBaoCao = [
    new AppMenuItem('Host_BaoCao', 'Pages.Host.BaoCao', 'fas fa-book-medical', '../../../assets/common/images/image-icon/report.svg', '/app/pages/host-bao-cao/bao-cao-cong-tac-yte', [], [
        new AppMenuItem('Host_BaoCao_KeHoachCongTacYTe', 'Pages.Host.BaoCao.KeHoachCongTacYTe', 'far fa-file-alt', '', '/app/pages/host-bao-cao/ke-hoach-cong-tac-yte'),
        new AppMenuItem('Host_BaoCao_DanhGiaCongTacYTe', 'Pages.Host.BaoCao.DanhGiaCongTacYTe', 'far fa-file-image', '', '/app/pages/host-bao-cao/danh-gia-cong-tac-yte'),
        new AppMenuItem('Host_BaoCao_BaoCaoCongTacYTe', 'Pages.Host.BaoCao.BaoCaoCongTacYTe', 'fas fa-file-medical-alt', '', '/app/pages/host-bao-cao/bao-cao-cong-tac-yte'),
    ])
];

//Quản lý tài khoản
export const QuanLyTaiKhoan = [
    new AppMenuItem('CoQuanQuanLy', 'Pages.QuanLyTaiKhoan', 'fas fa-archway', '../../../assets/common/images/image-icon/verified-account.svg', '/app/pages/quan-ly-tai-khoan/danh-sach-so-phong-giao-duc', [], [
        new AppMenuItem('CoQuanQuanLy_DanhSachTaiKhoan', 'Pages.QuanLyTaiKhoan.DanhSachTaiKhoan', 'fas fa-address-book', '', '/app/pages/quan-ly-tai-khoan/danh-sach-so-phong-giao-duc'),
    ])
];

//API hồ sơ sức khỏe
export const ApiHoSoSucKhoeMenuItem = [
    new AppMenuItem('ApiHoSoSucKhoe', 'Pages.ApiHoSoSucKhoe', 'fas fa-random', '../../../assets/common/images/image-icon/browser.svg', '/app/pages/api-hssk/api-du-lieu-kcb', [], [
        new AppMenuItem('ApiHoSoSucKhoe_DuLieuKCB', 'Pages.ApiHoSoSucKhoe.DuLieuKCB', 'fa fa-object-group', '', '/app/pages/api-hssk/api-du-lieu-kcb'),
        new AppMenuItem('ApiHoSoSucKhoe_CauHinh', 'Pages.ApiHoSoSucKhoe.CauHinh', 'fa fa-object-group', '', '/app/pages/api-hssk/cau-hinh'),
    ])
];

//API hồ sơ sức khỏe
export const ApiSmasMenuItem = [
    new AppMenuItem('ApiSmas', 'Pages.ApiSmas', 'fas fa-random', '../../../assets/common/images/image-icon/browser.svg', '/app/pages/api-smas/api-smas', [], [
        new AppMenuItem('ApiSmas_CauHinh', 'Pages.ApiSmas.CauHinh', 'fa fa-object-group', '', '/app/pages/api-smas/cau-hinh'),
    ])
];

// Thông tin tham khảo
export const ThongTinThamKhaoMenuItem = [
    new AppMenuItem('ThongTinThamKhao', 'Pages.ThongTinThamKhao', 'fas fa-book-reader', '../../../assets/common/images/image-icon/open-book.svg', '/app/pages/thong-tin-tham-khao/xem-thong-tin', [], [
        new AppMenuItem('ThongTinThamKhao_Category', 'Pages.ThongTinThamKhao.Category', 'fas fa-folder', '', '/app/pages/thong-tin-tham-khao/category'),
        new AppMenuItem('ThongTinThamKhao_Article', 'Pages.ThongTinThamKhao.Article', 'far fa-file-alt', '', '/app/pages/thong-tin-tham-khao/article'),
    ])
];

//#endregion


