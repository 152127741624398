import { DOCUMENT } from '@angular/common';
import {
    Component,
    ElementRef,
    Inject,
    Injector,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { AppMenu } from '@app/shared/layout/nav/app-menu';
import { AppMenuItem } from '@app/shared/layout/nav/app-menu-item';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PermissionCheckerService } from 'abp-ng2-module';
@Component({
    selector: 'menuheader-combo',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './menuheader-combo.component.html'
})
export class MenuHeaderComboComponent extends AppComponentBase implements OnInit {
    menus: AppMenu = null;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        public permission: PermissionCheckerService,
        private _appNavigationService: AppNavigationService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.menus = this._appNavigationService.getMenu();
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }
}
