<div>
    <div nz-col class="gutter-row" [nzSpan]="24">
        <h3 class="text-center font-weight-bolder margin-top-30">Mời bạn cấu hình lớp học cho năm học mới ({{namhoc}} - {{namhoc + 1}})</h3>
        <div class="text-center margin-bottom-30 ">(Dữ liệu lớp học được lấy của năm trước, nếu không thay đổi xin vui lòng bấm nút lưu)</div>
        <form nz-form [formGroup]="validateForm" nzLayout="horizontal" nz-row>
            <div nz-col class="gutter-row" [nzSpan]="24">
                <nz-table nzBordered="true" nzTemplateMode  [nzShowQuickJumper] = "true">
                    <thead>
                        <tr>
                            <th nzWidth="50px" nzAlign="center">STT</th>
                            <th>Tên lớp</th>
                            <th>Khối học </th>
                            <th nzWidth="200px">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of lopHocArr.controls;index as idx">
                            <tr [formGroup]="item">
                                <td nzAlign="center">{{idx+1}}</td>
                                <td>
                                    <nz-form-control nzErrorTip="Bạn chưa điền nội dung này" *ngIf="!item.value.id">
                                        <input nz-input formControlName="tenLop" placeholder="Tên lớp...">
                                    </nz-form-control>
                                    <label *ngIf="item.value.id">{{item.value.tenLop}}</label>
                                </td>
                                <td>
                                    <nz-form-control nzErrorTip="Bạn chưa điền nội dung này" *ngIf="!item.value.id">
                                        <khoi-hoc-combo formControlName="khoiHocId" ></khoi-hoc-combo>
                                    </nz-form-control>
                                    <label *ngIf="item.value.id">{{item.value.strKhoiHoc}}</label>
                                </td>
                                <td nzAlign="center">
                                    <button class="btn btn-outline-danger" (click)="removeLopHoc(idx)"><em class="far fa-trash-alt"></em> Xóa</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </nz-table>
                <div class="text-right margin-top-10"><button (click)="addLopHoc()" class="btn btn-outline-success"><em class="fas fa-plus"></em> Thêm lớp</button></div>
            </div>
        
            <nz-form-item>
                <nz-form-control>
                    <div *nzModalFooter>
                        <button type="submit" nz-button nzType="primary" (click)="save()"><em nz-icon nzType="save"></em>Lưu</button>
                        <button type="button" nz-button nzType="default" (click)="close()"><em nz-icon nzType="close"></em>Đóng</button>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
</div>

