import { finalize, mapTo } from 'rxjs/operators';
import { Injector, Component, OnInit, Inject } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { HttpClient } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd';
import { NavigationStart, Router } from '@angular/router';
import { BanHangServiceProxy, ThongTinTruongHocServiceProxy } from '@shared/service-proxies/service-proxies';
import { PermissionCheckerService } from 'abp-ng2-module';
import { CapNhatThongTinTruongHocModalComponent } from './modal/cap-nhat-thong-tin-truonghoc-modal.component';
import { fromEvent, merge } from 'rxjs';


@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    styleUrls: ['./default-layout.component.css'],
    animations: [appModuleAnimation()],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    namHoc = this.appSession.user.namHoc;
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/logo-light.png';
    userSession: any = {};
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private _thongTinTruongHocService: ThongTinTruongHocServiceProxy,
        private _banHangServiceProxy: BanHangServiceProxy,
        private modalService: NzModalService,
        private route: Router
    )
    {

        super(injector);
        //Kiểm tra xem có phải cập nhật thông tin cho trường không (mỗi khi thay đổi route)
        var permissionChecker = new PermissionCheckerService;
        route.events.subscribe((val) => {
            window.addEventListener('error', e => {

                // Nếu bị lỗi ChunkLoadError thì load lại trang
                if (/Loading chunk [\d]+ failed/.test(e.message)) {
                    window.location.reload();
                }
            });

            if (val instanceof NavigationStart) {

                if (this.appSession.user.isCheckLicense == "true") {
                    this._banHangServiceProxy.kiemTraHieuLucGoiCuocBySession().subscribe(result => {
                        if(result != true){
                           this.route.navigate(['/app/pages/demo-ui/goi-cuoc-het-han']);
                        }
                    });
                }
            }

        });

    }

    ngOnInit() {

        this.userSession = this.appSession;
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        //Kiểm tra xem có phải cập nhật thông tin cho trường không (lần đầu load)
        var permissionChecker = new PermissionCheckerService;
        if(this.appSession.tenant && this.appSession.tenant.id && permissionChecker.isGranted("Pages.CauHinhThongTin.CapNhatThongTin")){
            this._thongTinTruongHocService.kiemTraBatBuocCapNhatThongTin().subscribe((result) => {
                if(result == true){
                    let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
                    const modal = this.modalService.create({
                        nzTitle: ('Cập nhật thông tin trường học') + icon,
                        nzContent: CapNhatThongTinTruongHocModalComponent,
                        nzKeyboard: false,
                        nzClosable: false,
                        nzMaskClosable: false,
                        nzFooter: null,
                        nzWidth: "80%"
                    });
                }
            });
        }

        //Kiểm tra hết gói cước
        if (this.appSession.user.isCheckLicense == "true") {
            this._banHangServiceProxy.kiemTraHieuLucGoiCuocBySession().subscribe(result => {
                if(result != true){
                   this.route.navigate(['/app/pages/demo-ui/goi-cuoc-het-han']);
                }
            });
        }

    }
}
