import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as $ from 'jquery';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { HuyenComboComponent } from './huyen-combo.component';
import { XaComboComponent } from './xa-combo.component';

@Component({
    selector: 'tinh-huyen-xa-combo-full',
    template: `
        <div nz-row [nzGutter]="[16, 24]">
            <div nz-col class="gutter-row" [nzSpan]="8" id="{{now + '_tinh'}}" [nzMd]="8" [nzXs]="24" >
                <nz-form-label [nzRequired]="required">{{strTinh}}</nz-form-label>
                <tinh-combo #tinhCombo [(ngModel)]="maTinh" [disabled]="disabled || disabledTinh"
                            (ngModelChange)="huyenCombo.handlerTinhComboChange($event);maTinhChange.emit($event)"></tinh-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span class="form-control-feedback">{{messageTinh}}</span>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="8" id="{{now + '_huyen'}}"[nzMd]="8" [nzXs]="24">
                <nz-form-label [nzRequired]="required">{{strHuyen}}</nz-form-label>
                <huyen-combo #huyenCombo [(ngModel)]="maHuyen" [disabled]="disabled || disableHuyen"
                             (ngModelChange)="xaCombo.handlerHuyenComboChange($event);maHuyenChange.emit($event)"></huyen-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span class="form-control-feedback">{{messageHuyen}}</span>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="8" id="{{now + '_xa'}}"[nzMd]="8" [nzXs]="24">
                <nz-form-label [nzRequired]="required">{{strXa}}</nz-form-label>
                <xa-combo #xaCombo [(ngModel)]="maXa" [disabled]="disabled || disableXa"
                          (ngModelChange)="maXa = $event;maXaChange.emit($event)"></xa-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span class="form-control-feedback">{{messageXa}}</span>
                </div>
            </div>
        </div>
    `,
    styles: [`.ant-form-item-label {
        padding: 6px 0px !important;
    }`]
})
export class TinhHuyenXaSelectComponent implements OnChanges, AfterViewInit {
    @Input() disabled; //disable tat ca
    @Input() disabledTinh;
    @Input() disableHuyen;
    @Input() disableXa;
    @Input() strTinh = 'Tỉnh/ thành phố';
    @Input() strHuyen = 'Quận/ huyện';
    @Input() strXa = 'Xã/ phường';
    @Input() maTinh = '';
    @Output() maTinhChange = new EventEmitter();
    @Input() maHuyen = '';
    @Output() maHuyenChange = new EventEmitter();
    @Input() maXa = '';
    @Output() maXaChange = new EventEmitter();
    @Input() required = false;
    submitted = false;
    messageTinh = '';
    messageHuyen = '';
    messageXa = '';
    err = 'Bạn chưa điền nội dung này!';
    @ViewChild('huyenCombo') huyenCombo: HuyenComboComponent;
    @ViewChild('xaCombo') xaCombo: XaComboComponent;
    now = Number(new Date());

    constructor() {
        abp.event.on('tinh-huyen-xa-combo-full@validate', (submitted) => {
            if (this.required === true) {
                this.submitted = submitted;
                this.messageTinh = AppUtilityService.isNullOrEmpty(this.maTinh) ? this.err : '';
                this.messageHuyen = AppUtilityService.isNullOrEmpty(this.maHuyen) ? this.err : '';
                this.messageXa = AppUtilityService.isNullOrEmpty(this.maXa) ? this.err : '';
            }
        });
        abp.event.on('tinh-huyen-xa-select@focus', () => {
            if (AppUtilityService.isNullOrEmpty(this.maTinh)) {
                $('#' + this.now + '_tinh').find('.ant-select-selection-search-input').focus();
            }
            if (AppUtilityService.isNullOrEmpty(this.maHuyen)) {
                $('#' + this.now + '_huyen').find('.ant-select-selection-search-input').focus();
            }
            if (AppUtilityService.isNullOrEmpty(this.maXa)) {
                $('#' + this.now + '_xa').find('.ant-select-selection-search-input').focus();
            }

        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.required === true) {
            this.messageTinh = AppUtilityService.isNullOrEmpty(this.maTinh) ? this.err : '';
            this.messageHuyen = AppUtilityService.isNullOrEmpty(this.maHuyen) ? this.err : '';
            this.messageXa = AppUtilityService.isNullOrEmpty(this.maXa) ? this.err : '';
        }
        if (changes.maTinh && this.huyenCombo) {
            this.huyenCombo.getDataSourceFromServer(changes.maTinh.currentValue);
        }
        if (changes.maHuyen && this.xaCombo) {
            this.xaCombo.getDataSourceFromServer(changes.maHuyen.currentValue);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.huyenCombo.getDataSourceFromServer(this.maTinh);
			this.xaCombo.getDataSourceFromServer(this.maHuyen);
        });
    }
}
