<div class="ord-sub-header">
    <div [class]="containerClass + ' d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'">
        <div class="w-100">
            <div nz-row>
                <div nz-col nzSpan="12" nzXl="12" nzXXl="12" nzSm="12" nzXs="24">
                    <div class="header-title d-flex align-items-center flex-wrap mr-2 w-100" style="margin-top:3px;" *ngIf="title || description">
                        <nz-breadcrumb class="bold" nzSeparator=">">
                            <em class="fas fa-list"></em> &nbsp;
                            <nz-breadcrumb-item>
                                <a href="/app/main/home">Trang chủ</a>
                            </nz-breadcrumb-item>
                            <nz-breadcrumb-item *ngIf="title">
                                <span>{{title}}</span>
                            </nz-breadcrumb-item>
                            <nz-breadcrumb-item *ngIf="description">
                                {{description}}
                            </nz-breadcrumb-item>
                        </nz-breadcrumb>
                    </div>
                </div>
                <div nz-col nzSpan="12" nzXl="12" nzXXl="12" nzSm="12" nzXs="24">
                    <div class="d-flex align-items-center xs-bottom  oda-header-actions">
                        <ng-content select="div[role=actions]" ></ng-content>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="ord-sub-action">
    <div [class]="containerClass + ' d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap '">
        <div class="w-100">
            <div nz-row>
                <div nz-col nzSpan="19" nzXl="19" nzXXl="19" nzSm="18" nzXs="24">
                    <ng-content select="div[role=search]"></ng-content>
                </div>
                <div nz-col nzSpan="5"  nzXl="5" nzXXl="5" nzSm="6" nzXs="8" *ngIf="totalPage>0" class="mobile-none">
                    <div class="pull-right mtop-5">
                        <span class="bold mr-2">{{(pageIndex-1)*pageSize+1}} - {{pageSize*pageIndex>totalCount?totalCount:pageSize*pageIndex}} trong số {{totalCount}}</span>
                        <button class="btn btn-icon-item btn-sm" (click)="prevPage()">
                            <em class="fas fa-chevron-left"></em>
                        </button>
                        <button class="btn btn-icon-item btn-sm" (click)="nextPage()">
                            <em class="fas fa-chevron-right"></em>
                        </button>
                    </div>
                </div>
                <div  nz-col nzSpan="24" *ngIf="totalPage>0">
                    <b>Tìm thấy {{totalCount}} bản ghi</b>
                </div>
            </div>
        </div>
    </div>
</div>
