<form nzLayout="horizontal" id="danhSachGoiCuocDaDangKyFrom">
    <nz-table #baseTable [nzData]="nzTable.items"
                nzShowSizeChanger
                [nzLoading]="nzTable.loading" [nzTotal]="nzTable.totalCount"
                [nzShowPagination]="true"
                [nzShowQuickJumper] = "true"
                [(nzPageSize)]="nzTable.pageSize"
                nzBordered="true">
    <thead>
        <tr>
            <th nzWidth="50px" nzAlign="center">STT</th>
            <th>Tên gói cước</th>
            <th>Ngày bắt đầu hiệu lực</th>
            <th>Ngày kết thúc hiệu lực</th>
            <th>Ngày đăng ký</th>
            <th>Trạng thái</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of baseTable.data;index as idx">
            <td nzAlign="center">
                {{idx + 1}}
            </td>
            <td class="bold">
                {{item.tenGoiCuoc}} <label class="label font-weight-bold label-inline label-outline-success ml-2" *ngIf="item.isDangSuDung == true">Đang sử dụng</label>
            </td>
            <td>
                <strong>{{item.ngayHieuLuc | date:'dd/MM/yyyy'}}</strong>
            </td>
            <td>
                <strong>{{item.ngayKetThucHieuLuc | date:'dd/MM/yyyy'}}</strong>
            </td>
            <td>
                {{item.ngayDangKy | date:'dd/MM/yyyy'}}
            </td>
            <td>
                <span class="font-400" *ngIf="item.trangThai == TRANG_THAI_GOI_CUOC_DANG_KY.HOAT_DONG"><label class="label label-success"><em class="fas fa-check"></em></label> Kích hoạt</span>
                <span class="font-400" *ngIf="item.trangThai == TRANG_THAI_GOI_CUOC_DANG_KY.CHAN"><label class="label label-warning"><em class="fas fa-power-off"></em></label> Bị chặn</span>
                <span class="font-400" *ngIf="item.trangThai == TRANG_THAI_GOI_CUOC_DANG_KY.HUY"><label class="label label-danger"><em class="fas fa-times-circle"></em></label> Hủy</span>
            </td>
        </tr>
    </tbody>
    </nz-table>
</form>
