import { Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, AfterViewInit , Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from '@node_modules/rxjs/internal/operators';
import { NzContextMenuService, NzDropdownMenuComponent } from '@node_modules/ng-zorro-antd';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { nzTableHelper } from '@main_pages/shared/nzTableHelper';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { IOrdColumnOption } from '@main_pages/_base/pages-custom/ord-data-table/_subs/data-grid-ant/ord-data-table-col-template.component';
import * as $ from 'jquery';
import { AppPreBootstrap } from 'AppPreBootstrap';

export interface OrdColumnHeader {
    header: string;
    width?: string;
    align?: 'center' | 'left' | 'right';
    alignheader?: 'center' | 'left' | 'right';
    dataField?: string;
    render?: any;
    fixedColumn?: 'left' | 'right';
    rowSpan?: number;
    colSpan?: number;
}

export interface OrdColumn {
    /// cột act
    type: 'act' | 'render' | 'template' | 'dataField' | 'ord-custom' | 'checked' | 'renderHasTooltip';
    actions?: OrdActionGrid[];
    header: string;
    width?: string;
    align?: 'center' | 'left' | 'right';
    alignheader?: 'center' | 'left' | 'right';
    dataField?: string;
    render?: any;
    fixedColumn?: 'left' | 'right';
    rowSpan?: number;
    colSpan?: number;
    option?: {
        ordColumn?: IOrdColumnOption;
    };
    isShowChecked?: boolean;
    callbackFunction?: any;
    toolTip?: any;
}

export interface OrdActionGrid {
    name?: string;
    icon?: string;
    nzIcon?: string;
    callBack: any;
    color?: string;
    isHide?: any
}

export interface OrdDataTable {
    columns?: OrdColumn[];
    Mobilecolumns?: OrdColumn[];
    columnsHeader?: OrdColumnHeader[];
    actions?: OrdActionGrid[];
    acCheckbox?: OrdActionGrid[];
    pagingListUrl?: string;
    nzNotFoundContent?: any;
    scrollX?: string | null;
    scrollY?: string | null;
    hideHeader?: boolean;
}

@Component({
    selector: 'ord-data-table',
    templateUrl: './ord-data-table.component.html',
    styleUrls: ['./ord-data-table.component.css']
})
export class OrdDataTableComponent extends AppComponentBase implements OnInit {
    @Input() searchModel;
    @Input() dataTable: OrdDataTable;
    @Input() pagingListUrl = '';
    @Input() nzNotFoundContent?: any;
    @Input() dataCheckBox?: any; //dataChecked trước đó thường là array
    @Input() fieldCheckBox?: any; //fieldName checked :string
    @Input() columns: OrdColumn[];
    @Input() Mobilecolumns: OrdColumn[];
    @Input() columnsHeader: OrdColumnHeader[];
    @Input() columnTemplates: TemplateRef<any>;
    @Input() actions: OrdActionGrid[];
    @Input() acCheckbox: OrdActionGrid[];
    @Output() searchDataChangeEvent = new EventEmitter();
    @Output() dataFromTable = new EventEmitter();
    @Output() fnClickAllCheckbox = new EventEmitter();
    @Input() scrollX: string | null = null;
    @Input() scrollY: string | null = null;
    @Input() showHeader = true;
    @Input() DefaultFilter : any;
    @Input() checkboxAll: boolean = false
    @Input() showPagination:boolean = true;
    @Input() showQuickJumper:boolean = true;

    nzTable: nzTableHelper;
    //checkboxAll: boolean = false;
    constructor(injector: Injector,
        private nzContextMenuService: NzContextMenuService,
        private _httpClient: HttpClient) {
        super(injector);
        this.nzTable = new nzTableHelper();
    }

    action1 = [];
    action2 = [];

    ngOnInit(): void {
        this.actions = this.dataTable.actions;
        this.acCheckbox = this.dataTable.acCheckbox;
        var act1 = [];
        var act2 = [];
        this.actions.forEach(function(item, i){
            if(i<2){
                act1.push(item);
            }else{
                act2.push(item);
            }
        });

        this.action1 = act1;
        this.action2 = act2;

        this.columns = this.dataTable.columns;
        this.Mobilecolumns = this.dataTable.Mobilecolumns;
        this.columnsHeader = this.dataTable.columnsHeader;
        this.pagingListUrl = this.dataTable.pagingListUrl;
        abp.event.on(this.pagingListUrl + 'refreshList', () => {
            this.reload();
        });

        if(this.scrollY == null){
            var heigthScreen = $("body").height();
            var heightTabs = $(".tab-table-container-custom .ant-tabs-nav-container").outerHeight();
            var heightTab = (heightTabs != undefined) ? heightTabs : 0;
            this.scrollY = (heigthScreen - 200 - heightTab)+'px';

        }
    }

    // hàm call search from search box
    // defaultValue: any={
    //     namHoc:this.appSession.user.namHoc
    // }
    fnInitDefaultValue(){
        let value ={
            //namHoc:this.appSession.user.namHoc,
        };
       return value;
    }
    search(searchObj) {
        this.searchModel = Object.assign({}, searchObj);
        this.nzTable.pageIndex = 1;
        this.getDataGrids();
    }

    reload() {
        this.getDataGrids();
    }

    reloadAndSetPage(item) {
        this.nzTable.pageIndex = item.pageIndex;
        this.nzTable.pageSize = item.pageSize;
        this.getDataGrids();
    }

    getSearchRequest() {
        return {
            ...this.searchModel,
            skipCount: this.nzTable.getSkipCount(),
            maxResultCount: this.nzTable.getMaxResultCount()
        };
    }
    clickAllCheckbox() {

        for (let item of this.nzTable.items) {
            item.checked = this.checkboxAll;
        }
        let req = {
            items: this.nzTable.items
        }
        this.dataFromTable.emit(req);
        this.fnClickAllCheckbox.emit(this.checkboxAll);
    }

    addColClickEventListener(col,dataItem){
        if(col.callbackFunction)
            col.callbackFunction(dataItem);
    }

    getDataGrids() {
        const token = abp.auth.getToken();
        let header = new HttpHeaders({ "Authorization": "Bearer "+ token});
        const requestOptions = {  headers: header};
        if (AppUtilityService.isNullOrEmpty(this.pagingListUrl)) {
            return;
        }
        const url = AppConsts.remoteServiceBaseUrl + '/api/services/app/' + this.pagingListUrl;
        const req: any = {
            ...this.searchModel,
            ...this.fnInitDefaultValue(),
            //...this.DefaultFilter,
            skipCount: this.nzTable.getSkipCount(),
            maxResultCount: this.nzTable.getMaxResultCount()
        };
        this.searchDataChangeEvent.emit(req);
        abp.ui.setBusy();
        this._httpClient
            .post<any>(url, req, requestOptions)
            .pipe(finalize(() => {
                abp.ui.clearBusy();
            }))
            .subscribe(response => {
                let ret = response.result;
                this.nzTable.items = ret.items;
                //nếu dùng table có checkbox thì sẽ active bản ghi checked trước đó
                let checkTableCheckbox = this.columns.filter(m => m.type == 'checked');
                if (checkTableCheckbox.length > 0) {
                    let idxChecked = 0;
                    if(this.dataCheckBox!= null){
                        for (let item of this.nzTable.items) {
                            let getObj = this.dataCheckBox.find(b => b[this.fieldCheckBox] == item[this.fieldCheckBox]);
                            if (getObj != null)
                            {
                                idxChecked++;
                                item.checked = true;
                            }
                        }
                        if(idxChecked == this.nzTable.items.length && idxChecked>0){
                            this.checkboxAll = true;
                        }
                    }
                }

                this.nzTable.totalCount = ret.totalCount;
                this.dataFromTable.emit(ret);
                this.preOnePage();
                this.SetTotalPage();
            },
                error => {
                    console.log(error);
                });
    }
    SetTotalPage = function () {
        this.nzTable.totalPage = Math.ceil(this.nzTable.totalCount / this.nzTable.pageSize);
    };
    preOnePage() {
        if (this.nzTable.pageIndex > 1 && this.nzTable.items.length === 0 && this.nzTable.totalCount > 0) {
            this.nzTable.pageIndex -= 1;
            this.getDataGrids();
        }
    }

    getHeaderWidth(col: OrdColumn) {
        if (col.type === 'act') {
            return '200px';
        }
        return col.width;

    }

    getHeaderMobileWidth(col: OrdColumn) {
        if (col.type === 'act') {
            return '80px';
        }
        return col.width;

    }

    getHeaderFixWidth(col: OrdColumnHeader) {
        return col.width;

    }

    getHeaderAlign(col: OrdColumn) {
        if (col.type === 'act') {
            return 'center';
        }
        return col.alignheader;

    }

    getColAlign(col: OrdColumn) {
        if (col.type === 'act') {
            return 'center';
        }
        return col.align;
    }

    trachByFn(index: number, item: any) {
        if (item && item.id) {
            return item.id;
        }
        return index;
    }

    contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
        if (this.actions) {
            this.nzContextMenuService.create($event, menu);
        }
    }

    closeMenu(): void {
        this.nzContextMenuService.close();
    }

}
