import {Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'dropzone-file-upload',
    template: `
       <nz-upload nzType="drag" [nzMultiple]="false" (nzChange)="uploadTepDinhKem($event)" [nzAction]="uploadImportUrl"
       [(nzFileList)]="fileList" [nzAccept]="acceptType">
                        <p class="ant-upload-drag-icon">
                            <i nz-icon nzType="upload"></i>
                        </p>
                        <p class="ant-upload-text">Nhấn hoặc kéo thả file vào vùng này để upload</p>
        </nz-upload>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DropzoneFileUploadComponent),
        multi: true
    }]
})

export class DropzoneFileUploadComponent implements OnInit {
    notData = true;
    @Input() isView = false;
    @Input() sLimit? = 15;
    @Input() sModel: '';
    @Output() sModelChange = new EventEmitter();
    @Input() acceptType: string = '.xlsx,.xls';
    @Input() uploadImportUrl = AppConsts.remoteServiceBaseUrl + '/File/UploadTempFile';
    fileList  = [];
    constructor(
        private _service: UtilityServiceProxy) {
    }

    ngOnInit() {

    }

    beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      var indexOfPoint = file.name.lastIndexOf(".");
      var fileType = file.name.substring(indexOfPoint, file.name.length);
      const acceptTypeCheck = this.acceptType.includes(fileType);
      if (!acceptTypeCheck) {
        abp.notify.error("File upload phải đúng định dạng " + this.acceptType)
        observer.complete();
        return;
      }
      const mb = file.size! / 1024 / 1024;
      const isCheckSize = mb < this.sLimit;
      if (!isCheckSize) {
          abp.notify.error("File upload không được quá " + this.sLimit + "MB")
        observer.complete();
        return;
      }
      observer.next(acceptTypeCheck && isCheckSize);
      observer.complete();
    });

    public uploadTepDinhKem({
        file,
        fileList
    }: NzUploadChangeParam): void {
        if (file.status == 'error') {
            file.message = file.error.error.message;
            fileList = [];
            this.fileList = [];
        }

        if (file.response && file.response.success == true) {
            var dataRes = file.response;
            this.sModel = file.response;
            this.sModelChange.emit(this.sModel);
            this.fileList = [];
        }

    }
}
