import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { TYPE_VALIDATE } from '@shared/AppEnums';


@Component({
    selector: '<validation-custom>',
    template: `
    <span #mySpan class="form-control-feedback"
    [class]="isHidden ? '' : 'custom-error-validate'"
    style="display: none;"
    [hidden]="isHidden">
        {{messages}}
    </span>`
})
export class ValidationCustomComponent implements OnChanges, AfterViewInit {

    @ViewChild('mySpan') _mySpan: ElementRef;

    @Input() sModel: any;
    @Input() sType: TYPE_VALIDATE;
    @Input() messages: string;
    @Input() isNotValidateNullOrEmpty: boolean;
    @Input() nativeElement: any;

    isHidden: boolean = false;
    isAfterViewInit: boolean = false;

    checkHidden(): void {
        this.messages = AppUtilityService.isNullOrEmpty(this.messages)
            ? "Bạn chưa điền nội dung này"
            : this.messages;

        this.isHidden = true;
        if (AppUtilityService.isNullOrEmpty(this.sModel)) {
            this.isHidden = this.isNotValidateNullOrEmpty;
        }
        else if(!AppUtilityService.isNullOrEmpty(this.sModel) && (typeof this.sModel == "string")){ //Kiểm tra trường hợp chỉ nhập dấu cách
            var trim = this.sModel.trim();
            if(!trim){
                this.isHidden = this.isNotValidateNullOrEmpty;
            }
        }

        if (this.sType == TYPE_VALIDATE.Number && !AppUtilityService.validateNumber(this.sModel)) {
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Email && !AppUtilityService.validateEmail(this.sModel)) {
            this.messages = "Email không đúng định dạng";
            this.isHidden = false;
        }
        
        else if (this.sType == TYPE_VALIDATE.Moment && !AppUtilityService.validateMoment(this.sModel)) {
            this.messages = "Nhập ngày/tháng/năm";
            this.isHidden = false;
        }

        if (this.isAfterViewInit && this.nativeElement && this.nativeElement.style) {
            this.nativeElement.style.cssText = this.isHidden
                ? null
                : "color: #fd397a; border: 1px solid #fd397a;";
        }
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.isAfterViewInit) {
            this._mySpan.nativeElement.style.display = 'inline';
        }

        this.checkHidden();

    }

    ngAfterViewInit() {
        this.isAfterViewInit = true;
    }
}

