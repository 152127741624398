import { Input, Component, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'canbo-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzOpen]="autoOpen"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CanBoComboComponent),
        multi: true
    }]
})

export class CanBoComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy,private appSession:AppSessionService) {
        super();
    }

    @Input() autoOpen = false;

    @Output() getInfoCanBo = new EventEmitter();

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    onChangeValue(event: any): void {
        this.onChange(event);
        let canBo = this.optionListSource.find(x => x.value == event);
        if(canBo){
            this.getInfoCanBo.emit(canBo);
        }
    }

    getDataSourceFromServer() {
        let tenantId = this.appSession.tenant.id;
        let query = `SELECT id,tenCanBo FROM CB_CanBo where IsDeleted = 0 and trangThai = 1 and tenantId = @tenantId`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater:{
                tenantId: tenantId
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenCanBo,
                    fts: AppUtilityService.getFullTextSearch(it.tenCanBo)
                });
            });
            this.setListOfOption(lst);
        });
    }

}
