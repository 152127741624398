import { Component, Input } from '@angular/core';

export interface IOrdColumnOption {
    type: 'simple' | 'popover-hover';
    format?: {
        bold?: boolean;
        fontColor?: 'info' | 'danger'
    };
    popover?: {
        title: string;
        renderBody: any;
    };
}

@Component({
    selector: 'ord-column-template-render',
    template: `
        <ng-container [ngSwitch]="option.type">
            <ng-container *ngSwitchCase="'simple'">
                <span [ngClass]="getClass()">{{data}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'popover-hover'">
                <div [ngClass]="getClass()" nz-popover [nzPopoverTitle]="option.popover.title"
                     [nzPopoverContent]="contentTemplate"
                     nzPopoverPlacement="topLeft">{{data}}</div>
                <ng-template #contentTemplate>
                    <div [outerHTML]="option.popover.renderBody(this.record)">
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    `,
})

export class OrdDataTableColTemplateComponent {
    @Input() option: IOrdColumnOption;
    @Input() data: any;
    @Input() record: any;

    constructor() {
    }

    getClass() {
        let d = '';
        if (this.option.format) {
            if (this.option.format.bold === true) {
                d += ' kt-font-bold';
            }
            if (this.option.format.fontColor) {
                d += ' kt-font-' + this.option.format.fontColor;
            }
        }
        return d;
    }
}
