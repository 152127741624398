<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
     (onShown)="shown()"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{options.title}}
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form novalidate (ngSubmit)="refreshTable()" autocomplete="new-password">
                    <div *ngIf="options.isFilterEnabled" class="input-group mb-4">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzSuffix]="inputClearTpl">
                            <input  nz-input type="text" [(ngModel)]="filterText"  name="filterText" placeholder="{{'SearchWithThreeDot' | localize}}" />
                          </nz-input-group>
                          <ng-template #suffixIconButton>
                            <button nz-button  nzType="primary" type="submit" class="btn bg-green-ythd bg-font-green-ythd"><em class="flaticon-search-1" [attr.aria-label]="l('Search')"></em></button>
                          </ng-template>
                          <ng-template #inputClearTpl>
                            <i
                              nz-icon
                              class="ant-input-clear-icon"
                              nzTheme="fill"
                              nzType="close-circle"
                              *ngIf="filterText"
                              (click)="filterText = null"
                            ></i>
                        </ng-template>
                    </div>

                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getRecordsIfNeeds($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:15%">
                                        {{'Select' | localize}}    
                                    </th>
                                    <th>
                                        {{'Name' | localize}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td>
                                        <button class="btn bg-green-ythd bg-font-green-ythd btn-sm btn-icon"
                                                (click)="selectItem(record)"
                                                title="{{'Select' | localize}}">
                                            <em class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></em>
                                        </button>
                                    </td>
                                    <td>
                                        <span class="ui-column-title">{{'Name' | localize}}</span>
                                        {{record.name}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getRecordsIfNeeds($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </form>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Cancel" | localize}}</button>
            </div>

        </div>
    </div>
</div>
