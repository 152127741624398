import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as $ from 'jquery';
import {fromEvent} from '@node_modules/rxjs';
import {debounceTime} from '@node_modules/rxjs/internal/operators';
import {NzConfigService} from '@node_modules/ng-zorro-antd';
import {DanhMucXaServiceProxy, UtilityServiceProxy} from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import * as _ from 'lodash';

@Component({
    selector: 'xa-auto-complete',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './xa-auto-complete.component.html'
})
export class XaAutoCompleteComponent implements OnInit, OnChanges, AfterViewInit {
    inputValue = '';
    @Input() maTinh = '';
    @Output() maTinhChange = new EventEmitter();
    @Input() maHuyen = '';
    @Output() maHuyenChange = new EventEmitter();
    @Input() maXa = '';
    @Output() maXaChange = new EventEmitter();
    now = Number(new Date());
    source: any;
    @ViewChild('danhMucXaSelect') danhMucXaSelect: ElementRef;
    @ViewChild('customTpl', {static: false}) customTpl?: TemplateRef<any>;

    listOfData: any[] = [];
    listOfOption: any[] = [];
    textSearch = '';
    displayXa = '';
    loading = false;

    constructor(private _utilService: UtilityServiceProxy,
                private  _xaService: DanhMucXaServiceProxy,
                private nzConfigService: NzConfigService) {
    }

    ngOnInit(): void {
        this.getAllDanhMucXaFromDb();
    }

    getAllDanhMucXaFromDb() {
        let cache = sessionStorage.getItem('danh_muc_xa_all');
        if (cache) {
            this.listOfData = JSON.parse(cache);
        }
        this._xaService.getAll().subscribe(d => {
            this.listOfData = _.map(d.items, (it) => {
                return Object.assign({
                    ftsTinh: AppUtilityService.getFullTextSearch(it.tenTinh),
                    ftsHuyen: AppUtilityService.getFullTextSearch(it.tenHuyen),
                    ftsXa: AppUtilityService.getFullTextSearch(it.tenXa),
                }, it);
            });
            sessionStorage.setItem('danh_muc_xa_all', JSON.stringify(this.listOfData));
            return d;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maXa) {
            if (AppUtilityService.isNullOrEmpty(changes.maXa.currentValue)) {
                this.displayXa = '';
                this.inputValue = '';
                this.listOfOption = [];
                return;
            }
            this.getDisplayXa();
        }
    }

    isNull(d) {
        return AppUtilityService.isNullOrEmpty(d);
    }

    changeSelect(d) {
        if (this.isNull(d)) {
            this.setMa('', '', '');
            return;
        }
        let f = this.listOfOption.find(x => {
            return x.maXa === d;
        });
        this.setMa(f.maTinh, f.maHuyen, f.maXa);
        setTimeout(() => {
            $('#ten_xa_' + this.now).focus();
        }, 333);
    }

    unSelect() {
        this.setMa('', '', '');
        this.inputValue = '';
        this.listOfOption = [];
        setTimeout(() => {
            $('#select_' + this.now).find('.ant-select-selection-search-input').focus();
        }, 333);
    }

    setMa(maTinh, maHuyen, maXa) {
        this.maXa = maXa;
        this.maHuyen = maHuyen;
        this.maTinh = maTinh;
        this.maXaChange.emit(maXa);
        this.maHuyenChange.emit(maHuyen);
        this.maTinhChange.emit(maTinh);
        this.displayXa = this.getDisplayXa();
    }

    handlerSearch(value: string): void {
        this.textSearch = value;
    }

    filterListOfOption(value: string) {
        if (AppUtilityService.isNullOrEmpty(value)) {
            this.listOfOption = [];
            return;
        }
        let arr = value.split('-');
        if (arr.length === 1) {
            let s = AppUtilityService.getFullTextSearch(arr[0]);
            this.listOfOption = this.listOfData.filter(it => {
                return it.ftsXa.indexOf(s) > -1 || it.ftsHuyen.indexOf(s) > -1 || it.ftsTinh.indexOf(s) > -1;
            }).slice(0, 30);
            return;
        }
        if (arr.length === 2) {
            let s1 = AppUtilityService.getFullTextSearch(arr[0]);
            let s2 = AppUtilityService.getFullTextSearch(arr[1]);
            this.listOfOption = this.listOfData.filter(it => {
                return (it.ftsXa.indexOf(s1) > -1 && it.ftsHuyen.indexOf(s2) > -1)
                    || (it.ftsHuyen.indexOf(s1) > -1 && it.ftsTinh.indexOf(s2) > -1);
            }).slice(0, 30);
            return;
        }
        let s1 = AppUtilityService.getFullTextSearch(arr[0]);
        let s2 = AppUtilityService.getFullTextSearch(arr[1]);
        let s3 = AppUtilityService.getFullTextSearch(arr[2]);
        this.listOfOption = this.listOfData.filter(it => {
            return it.ftsXa.indexOf(s1) > -1 && it.ftsHuyen.indexOf(s2) > -1 && it.ftsTinh.indexOf(s3) > -1;
        }).slice(0, 30);
        return;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.inputValue = this.maXa;
            this.source = fromEvent(this.danhMucXaSelect.nativeElement, 'keyup');
            this.source.pipe(debounceTime(868)).subscribe(c => {
                    if (c.keyCode === 38 || c.keyCode === 40) {
                        return;
                    }
                    this.filterListOfOption(this.textSearch);
                }
            );
            this.nzConfigService.set('empty', {nzDefaultEmptyContent: this.customTpl});
        });
    }

    trackByFn(index: number, item: any) {
        if (item && item.id) {
            return item.id;
        }
        return index;
    }

    getDisplayXa() {
        if (this.isNull(this.maXa)) {
            return '';
        }
        let f = this.listOfOption.find(x => {
            return x.maXa === this.maXa;
        });
        return f ? `${f.tenXa} - ${f.tenHuyen} - ${f.tenTinh}` : '';
    }

    getFullTenTinhHuyenXa(item) {
        return `${item.tenXa} - ${item.tenHuyen} - ${item.tenTinh}`;
    }


}
