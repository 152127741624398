<div class="content content-form d-flex flex-column flex-column-fluid" [@routerTransition]>
    <div class="ord-sub-header">
        <div style="margin-top: 3px;" class="header-title">
            <nz-breadcrumb class="bold" nzSeparator=">">
                <em class="fas fa-list"></em> &nbsp;
                <nz-breadcrumb-item>
                  <span>Không tìm thấy trang</span>
                </nz-breadcrumb-item>
            </nz-breadcrumb>
        </div>
        <div class="header-tool">
            
        </div>
    </div>
    
    <div class="mt-3" [class]="containerClass" style="padding-bottom: 15px;" id="FormThongTin">
        <div>
            <h3 class="mt-5 text-center text-uppercase font-weight-bolder font-green-ythd" >
                Không tìm thấy trang muốn truy cập
            </h3>
            <p class="italic text-center mt-3">Bạn đang cố gắng vào một trang không tồn tại hoặc bạn không có quyền truy cập trang</p>
            <p class="italic text-center">Xin vui lòng liên hệ quản trị viên nếu bạn nghĩ rằng có sự cố xảy ra với bạn</p>
        </div>
        <div class="text-center">
            <button class="btn bg-green-ythd bg-font-green-ythd" (click)="homePage()">
                <em class="fa fa-list"></em> Đến danh sách chức năng
            </button>
        </div>
    </div>
</div>
