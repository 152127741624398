import {
    Component, OnInit, ElementRef, EventEmitter, Injector, Output, ViewChild,
    KeyValueDiffer, Input, AfterViewInit
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule  } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import { delay, finalize } from 'rxjs/operators';
import {
    DynamicFormPageComponent,
    OrdFormItem
} from '@main_pages/_base/form-custom/dynamic-form/dynamic-form-page.component';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import * as $ from 'jquery';
import { DragDrop } from '@angular/cdk/drag-drop';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ResponseCodeStatus, ThongTinTruongHocServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppMainComponentBase } from '@main_pages/_base/app-main.component';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
@Component({
    selector: 'app-cap-nhat-thong-tin-truonghoc-modal',
    templateUrl: './cap-nhat-thong-tin-truonghoc-modal.component.html'
})

export class CapNhatThongTinTruongHocModalComponent extends AppMainComponentBase implements OnInit{
   
    thongTinCapNhat : any = {};

    capNhatLanDau = false;

    disableTinh = false;

    constructor(
        private _httpClient: HttpClient,
        injector: Injector,
        private modal: NzModalRef,
        private fb: FormBuilder,
        private dragDrop: DragDrop,
        private _thongTinTruongHocService: ThongTinTruongHocServiceProxy,
        private elementRef:ElementRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getThongTinTruongHoc();
    }

    getThongTinTruongHoc(){
        abp.ui.setBusy();
        this._thongTinTruongHocService.getThongTinTruongHocCapNhat().subscribe((result) => {
            this.thongTinCapNhat = result;
            this.pauseValidateForm();
            abp.ui.clearBusy();
        });
    }

    pauseValidateForm(){
        //pause validate do dataItem thay đổi
        setTimeout(()=>{
            AppUtilityService.PauseValidateForm("FormThongTin");
        })
    }

    checkValidate(){
        abp.event.trigger('tinh-huyen-xa-combo-full@validate',true);
        if (AppUtilityService.IsNullValidateForm("FormThongTin")) {
            abp.notify.error("Dữ liệu trống hoặc chưa hợp lệ", "Xin vui lòng xem lại");
             //focus vào thông tin bị trống
             let dom = this.elementRef.nativeElement;
             const elements = dom.querySelectorAll('.custom-error-validate') as NodeListOf<HTMLElement>;
             if(elements && elements.length > 0){
                 elements[0].scrollIntoView({block: "center", inline: "nearest"});
             }
            return false;
        }

        var hoDem = this.thongTinCapNhat.hoTenNguoiPhuTrach.split(" ").slice(0, -1).join(' ');
        var ten = this.thongTinCapNhat.hoTenNguoiPhuTrach.split(" ").slice(-1).join(' ');
        if(!hoDem || !ten){
            abp.notify.error("Hãy nhập cả họ và tên", "Xin vui lòng xem lại");
            return false;
        }

        return true;
    }

    save(){
        const validate = this.checkValidate();
        if(!validate){
            return;
        }
        var input = Object.assign({}, this.thongTinCapNhat);
        input.hoDemNguoiPhuTrach = this.thongTinCapNhat.hoTenNguoiPhuTrach.split(" ").slice(0, -1).join(' ');
        input.tenNguoiPhuTrach = this.thongTinCapNhat.hoTenNguoiPhuTrach.split(" ").slice(-1).join(' ');
        abp.ui.setBusy();
        this._thongTinTruongHocService.capNhatThongTinTruongHoc(input).subscribe((result) => {
            if(result && result.code == ResponseCodeStatus.ThanhCong){
                this.notify.success(this.l('SavedSuccessfully'));
                this.close();
                setTimeout(()=>{
                    location.reload();
                }, 1000)
            }
            else
            {
                abp.notify.error(result.message);
            }
            abp.ui.clearBusy();
        });
    }

   
    close(): void {
        this.modal.destroy();
    }

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    ngAfterViewInit(): void {
        this.fullScreenClick();
    }
}
