import { Component, forwardRef, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'truong-hoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn trường học ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='notData || _isDisabled'
                   (nzFocus)="onFocus($event)"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event);"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TruongHocComboComponent),
        multi: true
    }]
})

// get trường học by maTinh và maHuyen, nếu muốn get danh sách trường thì tạo combo khác
export class TruongHocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {

    notData = false;

    maTinh = '';

    constructor(private _service: UtilityServiceProxy) {
        super();
        this._isDisabled = true;
    }

    ngOnInit() {
        this.notData = true;
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }

        // listen event tinh-huyen-select@tinhChange from tinh-huyen-select component
        abp.event.on('tinh-huyen-select@tinhChange', (maTinh,maHuyen) => {
            this.maTinh = maTinh;
            this.getDataSourceFromServer(maTinh,maHuyen);
        });

        // listen event tinh-huyen-select@tinhChange from huyen-combo component
        abp.event.on('huyen-combo@huyenChange', (maHuyen) => {
            this.getDataSourceFromServer(this.maTinh,maHuyen);
        });

    }

    getDataSourceFromServer(maTinh,maHuyen) {

        this.notData = false;

        if (AppUtilityService.isNullOrEmpty(maTinh)) {
            this.setListOfOption([]);
            this.notData = true;
            this._value = null;
            return;
        }
        else if(AppUtilityService.isNullOrEmpty(maHuyen)){
            this.setListOfOption([]);
            this.notData = true;
            this._value = null;
            return;
        }

        let query = `SELECT id,maTinh,maHuyen,tentruong
        FROM abptenants
        where IsDeleted = 0 and maTinh = @MaTinh and IsActive = 1`;

        if(maHuyen){
            query = `SELECT id,maTinh,maHuyen,tentruong
                        FROM abptenants
                        where IsDeleted = 0 and maTinh = @MaTinh and maHuyen = @maHuyen and IsActive = 1`;
        }

        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                maTinh: maTinh,
                maHuyen: maHuyen
            }
        };

        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tentruong,
                    fts: AppUtilityService.getFullTextSearch(it.tentruong)
                });
            });

            this.setListOfOption(lst);
        });
    }
}
