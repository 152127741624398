import { RoleLevelComboComponent } from './controls/_enum/role-level-combo.component';
import { ThuocTaiTruongComboComponent } from './controls/dm-thuoc/thuoc-tai-truong-combo-component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { AntDesignModule } from '@main_pages/shared/ant-design.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { TinhComboComponent } from '@main_pages/modules/_common/controls/tinh-combo.component';
import { HuyenComboComponent } from '@main_pages/modules/_common/controls/huyen-combo.component';
import { XaComboComponent } from '@main_pages/modules/_common/controls/xa-combo.component';
import { TinhHuyenSelectComponent } from '@main_pages/modules/_common/controls/tinh-huyen-select.component';
import { TinhHuyenXaSelectComponent } from '@main_pages/modules/_common/controls/tinh-huyen-xa-select.component';
import { XaAutoCompleteComponent } from '@main_pages/modules/_common/controls/xa-auto-complete.component';
import { QuocGiaComboComponent } from './controls/quocgia-combo.component';
import { VungComboComponent } from './controls/vung-combo.component';
import { ThangComboComponent } from './controls/thang-combo.component';
import { NamComboComponent } from './controls/nam-combo.component';
import { NhaCungCapComboComponent } from './controls/nhacungcap-combo.component';
import { QLATTPNhaCungCapComboComponent } from './controls/vesinhattp-nhacungcap-combo.component';
import { MenuHeaderComboComponent } from './controls/menuheader-combo.component';
import { TinhTrangThietBiComboComponent } from './controls/tinhtrangthietbi-combo.component';
import { NguyenLieuThucPhamComboComponent } from './controls/nguyenlieuthucpham-combo.component';
import { KhuVucTruongHocComboComponent } from './controls/khuvuctruonghoc-combo.component';
import { ThietBiYTeComboComponent } from './controls/thietbiyte-combo.component';
import { HocSinhComboComponent } from './controls/danh-muc-chung/hocsinh-combo.component ';
import { DMThuocComboComponent } from './controls/dm-thuoc/dm-thuoc-combo.component';
import { CapHocComboComponent } from './controls/danh-muc-chung/cap-hoc-combo.component';
import { KhoiHocComboComponent } from './controls/danh-muc-chung/khoi-hoc-combo.component';
import { LopComboComponent } from './controls/danh-muc-chung/lop-combo.component';
import { LopCombo2Component } from './controls/danh-muc-chung/lop-get-data-combo.component';
import { TiemChungComboComponent } from './controls/hoc-sinh/tiem-chung-combo.component';
import { ThangHocComboComponent } from './controls/danh-muc-chung/thanghoc-combo.component';
import { TinhTrangChiSoSucKhoeComboComponent } from './controls/danh-muc-chung/tinhtrangchisosuckhoe-combo.component';
import { CoSoComboComponent } from './controls/danh-muc-chung/coso-combo.component';
import { PhatHienNguyCoComboComponent } from './controls/hoc-sinh/phat-hien-nguy-co-combo.component';
import { DanTocComboComponent } from './controls/dan-toc-combo.component';
import { APITinhComboComponent } from './controls/API/api-tinh-combo.component';
import { ApiTruongHocComboComponent } from './controls/API/api-truong-hoc-combo.component';
import { APIHuyenComboComponent } from './controls/API/api-huyen-combo.component';
import { APITinhHuyenSelectComponent } from './controls/API/api-tinh-huyen-select.component';
import { APIXaComboComponent } from './controls/API/api-xa-combo.component';
import { APITinhHuyenXaSelectComponent } from './controls/API/api-tinh-huyen-xa-select.component';
import { APIDanTocComboComponent } from './controls/API/api-dan-toc-combo.component';
import { APITonGiaoComboComponent } from './controls/API/api-ton-giao-combo.component';
import { CanBoComboComponent } from './controls/can-bo/canbo-combo.component';
import { TenantComboComponent } from './controls/tenant-combo.component';
import { CapTruongHocComboComponent } from './controls/_enum/captruonghoc-combo.component';
import { DMThuocDonViTinhComboComponent } from './controls/dm-thuoc/donvitinh-combo.component';
import { DMThuocHanSuDungComboComponent } from './controls/dm-thuoc/thuoc-hansudung-combo';
import { HocSinhAutoCompleteComponent } from './controls/autocomplete/hoc-sinh-auto.component';
import { TruongHocComboComponent } from './controls/truong-hoc-combo.component';
import { TrangThaiHocSinhComboComponent } from './controls/hoc-sinh/trang-thai-hoc-sinh-combo.component';
import { NoiKhamChuaBenhBanDauComboComponent } from './controls/noikhamchuabenhbandau-combo.component';
import { NamLopSelectComponent } from './controls/danh-muc-chung/nam-lop-select.component';
import { DropzoneFileUploadComponent } from './controls/dropzone-upload-file-component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppCommonModule,
        UtilsModule,
        AntDesignModule,
        AppRoutingModule

    ],
    declarations: [
        TinhComboComponent,
        HuyenComboComponent,
        XaComboComponent,
        TinhHuyenSelectComponent,
        TinhHuyenXaSelectComponent,
        XaAutoCompleteComponent,
        QuocGiaComboComponent,
        VungComboComponent,
        ThangComboComponent,
        NamComboComponent,
        NhaCungCapComboComponent,
        QLATTPNhaCungCapComboComponent,
        MenuHeaderComboComponent,
        TinhTrangThietBiComboComponent,
        NguyenLieuThucPhamComboComponent,
        DMThuocComboComponent,
        ThuocTaiTruongComboComponent,
        KhuVucTruongHocComboComponent,
        ThietBiYTeComboComponent,
        CapHocComboComponent,
        KhoiHocComboComponent,
        HocSinhComboComponent,
        LopComboComponent,
        LopCombo2Component,
        TiemChungComboComponent,
        ThangHocComboComponent,
        TinhTrangChiSoSucKhoeComboComponent,
        CoSoComboComponent,
        PhatHienNguyCoComboComponent,
        DanTocComboComponent,
        APITinhComboComponent,
        ApiTruongHocComboComponent,
        APIHuyenComboComponent,
        APITinhHuyenSelectComponent,
        APIXaComboComponent,
        APITinhHuyenXaSelectComponent,
        APIDanTocComboComponent,
        APITonGiaoComboComponent,
        CanBoComboComponent,
        TenantComboComponent,
        CapTruongHocComboComponent,
        DMThuocDonViTinhComboComponent,
        DMThuocHanSuDungComboComponent,
        RoleLevelComboComponent,
        HocSinhAutoCompleteComponent,
        TruongHocComboComponent,
        TrangThaiHocSinhComboComponent,
        NoiKhamChuaBenhBanDauComboComponent,
        NamLopSelectComponent,
        DropzoneFileUploadComponent
    ],
    exports: [
        TinhComboComponent,
        HuyenComboComponent,
        XaComboComponent,
        TinhHuyenSelectComponent,
        TinhHuyenXaSelectComponent,
        XaAutoCompleteComponent,
        QuocGiaComboComponent,
        VungComboComponent,
        ThangComboComponent,
        NamComboComponent,
        NhaCungCapComboComponent,
        QLATTPNhaCungCapComboComponent,
        MenuHeaderComboComponent,
        TinhTrangThietBiComboComponent,
        NguyenLieuThucPhamComboComponent,
        DMThuocComboComponent,
        ThuocTaiTruongComboComponent,
        KhuVucTruongHocComboComponent,
        ThietBiYTeComboComponent,
        CapHocComboComponent,
        KhoiHocComboComponent,
        HocSinhComboComponent,
        LopComboComponent,
        LopCombo2Component,
        TiemChungComboComponent,
        ThangHocComboComponent,
        TinhTrangChiSoSucKhoeComboComponent,
        CoSoComboComponent,
        PhatHienNguyCoComboComponent,
        DanTocComboComponent,
        APITinhComboComponent,
        ApiTruongHocComboComponent,
        APIHuyenComboComponent,
        APITinhHuyenSelectComponent,
        APIXaComboComponent,
        APITinhHuyenXaSelectComponent,
        APIDanTocComboComponent,
        APITonGiaoComboComponent,
        CanBoComboComponent,
        TenantComboComponent,
        CapTruongHocComboComponent,
        DMThuocDonViTinhComboComponent,
        DMThuocHanSuDungComboComponent,
        RoleLevelComboComponent,
        HocSinhAutoCompleteComponent,
        TruongHocComboComponent,
        TrangThaiHocSinhComboComponent,
        NoiKhamChuaBenhBanDauComboComponent,
        NamLopSelectComponent,
        DropzoneFileUploadComponent
    ]
})
export class OrdControlCommonModule {
}
