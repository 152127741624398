import { Component, forwardRef, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'lop-get-data-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn lớp"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><i class="fa fa-caret-down font-weight-bold" aria-hidden="true"></i></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LopCombo2Component),
        multi: true
    }]
})

export class LopCombo2Component extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy) {
        super();
        this._isDisabled = true;
    }
    @Input() IsChangeData: boolean = false;
    @Output() onChangeFinishedEmitter = new EventEmitter();
    // @Output() strValue : EventEmitter<string> =new EventEmitter<string>();
    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        abp.event.on('lop-combo-get-by-khoi', (khoiHocId) => {
            this.getDataSourceFromServer(khoiHocId);
            return;
        });

        this.getDataSourceFromServer(null);
    }

    onChangeValue = (v: any) => {
        let item =this.getItemSelected(v);
        this.onChangeFinishedEmitter.emit(item);
    };

    handlerKhoiComboChange(khoiHocId) {
        this.getDataSourceFromServer(khoiHocId);
        let f = this.optionList.find(it => {
            return it.value === this._value;
        });
        if (AppUtilityService.isNullOrEmpty(f)) {
            this._value = null;
            this.onChangeValue(null);
        }
    }

    getDataSourceFromServer(khoiHocId) {
        const key = 'lop-combo-data-' + khoiHocId;
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT khoiHocId,tenLop,id
        FROM DM_LopHoc
        where IsActive = 1 and khoiHocId = @khoiHocId`;
        if(khoiHocId == null){
            query = `SELECT khoiHocId,tenLop,id
            FROM DM_LopHoc
            where IsActive = 1`;
        }
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                khoiHocId: khoiHocId
            }
        };

        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenLop,
                    fts: AppUtilityService.getFullTextSearch(it.tenLop)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

    getItemSelected(id){
        let f = this.optionList.find(it => {
            return it.value === id;
        });
        return f;
    }
}
