import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BanHangServiceProxy, DanhSachGoiCuocDaDangKyInput, ProfileServiceProxy, TRANG_THAI_GOI_CUOC_DANG_KY, UserLoginAttemptDto, UserLoginServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import { AppMainComponentBase } from '@main_pages/_base/app-main.component';
import { nzTableHelper } from '@main_pages/shared/nzTableHelper';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'danh-sach-goi-cuoc-da-dang-ky',
    templateUrl: './danh-sach-goi-cuoc-da-dang-ky-modal.component.html'
})
export class DanhSachGoiCuocDaDangKyModalComponent  extends AppComponentBase implements OnInit {

    nzTable: nzTableHelper;

    TRANG_THAI_GOI_CUOC_DANG_KY = TRANG_THAI_GOI_CUOC_DANG_KY;

    constructor(
        injector: Injector,
        private modal: NzModalRef,
        private _banHangServiceProxy: BanHangServiceProxy
    ) {
        super(injector);
        this.nzTable = new nzTableHelper();
    }

    ngOnInit(): void {
        this.getData(true);
    }

    getData(restPage: boolean = false) {
        this.nzTable.shouldResetPaging(restPage);
        abp.ui.setBusy();
        var input : any = {
            maxResultCount: this.nzTable.getMaxResultCount(),
            skipCount: this.nzTable.getSkipCount()
        }
        this._banHangServiceProxy.danhSachGoiCuocDaDangKy(input).pipe(finalize(() => abp.ui.clearBusy())).subscribe(result => {
            this.nzTable.items = result.items;
            this.nzTable.totalCount = result.items.length;
            this.preOnePage();
            this.setTotalPage();
        });
    }
    
    setTotalPage = function () {
        this.nzTable.totalPage = Math.ceil(this.nzTable.totalCount / this.nzTable.pageSize);
    };

    preOnePage() {
        if (this.nzTable.pageIndex > 1 && this.nzTable.items.length === 0 && this.nzTable.totalCount > 0) {
            this.nzTable.pageIndex -= 1;
        }
    }

    close(isSave?: boolean): void {
        this.modal.destroy(isSave);
    }


    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header'
        });

    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }

}
