import {Input,
    Component, forwardRef, OnInit, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy, DMThuocServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { Output } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'dm-thuoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn thuốc"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DMThuocComboComponent),
        multi: true
    }]
})

export class DMThuocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _httpClient: HttpClient,
                private _service: UtilityServiceProxy,
                private appSession: AppSessionService) {
        super();
    }
    
    @Output() getInfoThuoc = new EventEmitter();
    baseUrlService = '/api/services/app/DMThuoc/';
    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    onChangeValue(v) {
        let a = this.optionList.find(x => x.value === v);
        this.getInfoThuoc.emit(a);
        this.onChange(v);
    }

    getDataSourceFromServer() {
        let tenantId = this.appSession.tenant.id;
        const url = AppConsts.remoteServiceBaseUrl + this.baseUrlService + 'GetListThuoc?tenantId=' + tenantId;
        this._httpClient
                .post<any>(url, null)
                .pipe()
                .subscribe(response => {
                    let lst = _.map(response.result, (it) => {
                        return Object.assign({}, {
                            value: it.id,
                            displayText: it.tenThuoc,
                            fts: AppUtilityService.getFullTextSearch(it.tenThuoc)
                        });
                    });
                    this.setListOfOption(lst);
                });
    }
}
