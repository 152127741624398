
<div appBsModal #changeProfilePictureModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changeProfilePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"ChangeProfilePicture" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- <div class="form-group">
                        <label for="Profile_UseGravatarProfilePicture" class="checkbox">
                            <input id="Profile_UseGravatarProfilePicture" type="checkbox"
                                name="UseGravatarProfilePicture" [(ngModel)]="useGravatarProfilePicture">
                            {{"UseGravatarProfilePicture" | localize}}
                            <span></span>
                        </label>
                    </div> -->
                    <div class="form-group">
                        <div class="dropImage mb-2">
                            <div class="mt-2">
                                <em class="fas fa-upload"></em> Nhấn hoặc kéo thả file
                            </div>
                            <input type="file" #fileUpload id="fileUpload" [disabled]="useGravatarProfilePicture" name="fileUpload" (change)="fileChangeEvent($event)" accept=".jpg,.png,.jpeg" />
                        </div>

                        <!-- <div class="box__input">
                            <input type="file" (change)="fileChangeEvent($event)" accept="image/*" [disabled]="useGravatarProfilePicture" />
                        </div> -->
                        <span class="help-block m-b-none"
                            [hidden]="useGravatarProfilePicture">{{"ProfilePicture_Change_Info" | localize:maxProfilPictureBytesUserFriendlyValue}}</span>
                    </div>
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4" [resizeToWidth]="128" format="png"
                        (imageCropped)="imageCroppedFile($event)" [hidden]="useGravatarProfilePicture"></image-cropper>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-default bg-font-default font-weight-bold" (click)="close()"
                        [disabled]="saving"><em class="fas fa-times"></em> {{"Cancel" | localize}}</button>
                    <button type="submit" class="btn bg-green-ythd bg-font-green-ythd font-weight-bold"
                        [disabled]="!changeProfilePictureModalForm.form.valid || saving"><em
                            class="fa fa-save font-white"></em> <span> {{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
