import {
    Input,
    Component, forwardRef, OnInit, Injector, EventEmitter, Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HSHocSinhServiceProxy, UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'hocsinh-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => HocSinhComboComponent),
        multi: true
    }]
})

export class HocSinhComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    appSession: AppSessionService;
    constructor(injector: Injector,
                private _service: UtilityServiceProxy,
                private _hocsinhService: HSHocSinhServiceProxy) {
        super();
        this.appSession = injector.get(AppSessionService);
    }

    @Input() lopHocId: number;
    @Output() gioiTinh: EventEmitter<any> = new EventEmitter<any>();
    @Output() getInfoHocSinh = new EventEmitter<any>();
    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }

        if (this.lopHocId != null) {
            this.getDataSourceFromServer(this.lopHocId);
        }
        else {
            this.getDataSourceFromServer(null);
        }
    }

    handlerLopHocComboChange(lopHocId) {
        this.getDataSourceFromServer(lopHocId);
        setTimeout(()=>{
            let f = this.optionList.find(it => {
                return it.value === this._value;
            });
            if (AppUtilityService.isNullOrEmpty(f)) {
                this._value = null;
                this.onChangeValue(null);
            }
        }, 300);
    }

    onChangeValue(v) {
        this.onChange(v);
        let a = this.optionList.find(x => x.value === v)
        if(a!= null)
        {
            this.gioiTinh.emit(a.gioiTinh);
            this.getInfoHocSinh.emit(a);
        }
    }

    getDataSourceFromServer(lopHocId) {
        let lopId = lopHocId > 0 ? lopHocId : 0;
        const key = 'danh-muc-hocsinh-combo-data-' + lopId;
        let namHocNow = this.appSession.user.namHoc;
        let tenantId = this.appSession.tenant.id;
        this._hocsinhService.getListHocSinhShared(lopId, tenantId, namHocNow).subscribe((result) => {
            if(result != null){
                let lst = _.map(result, (it) => {
                    return Object.assign({}, {
                        value: it.id,
                        displayText: `${it.tenHocSinh} (${moment(it.ngaySinh).format('DD/MM/YYYY')})`,
                        tenHocSinh:it.tenHocSinh,
                        gioiTinh:it.gioiTinh,
                        lopHocId:it.lophocId,
                        ngaySinh:it.ngaySinh,
                        isDongKinh:it.isDongKinh,
                        isHen:it.isHen,
                        isTimBamSinh:it.isTimBamSinh,
                        isDiUng:it.isDiUng,
                        benhDangDieuTri:it.benhDangDieuTri,
                        fts: AppUtilityService.getFullTextSearch(it.tenHocSinh)
                    });
                });
                sessionStorage.setItem(key, JSON.stringify(lst));
                this.setListOfOption(lst);
            }
        });
    }

}
