import {Component, Injector, Input} from '@angular/core';
import * as _ from 'lodash';
import {AppUtilityService} from '@main_pages/_base/services/app-utility.service';
@Component({
    template: ''
})
export abstract class BaseComboBoxComponent {
    @Input() placeHolder = 'Chọn...';
    @Input() isMulti: any;
    nzMode = 'default';
    nzPlaceHolder = 'Chọn...';
    _value:number = 0;
    public optionList: any[] = [];
    public optionListSource: any[] = [];
    _isDisabled = false;
    public onChange = (v: any) => {
    };
    private onTouched = () => {
    };

    @Input()
    get value() {
        return this.value;
    }

    set value(v: any) {
        this._value = v;
    }

    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    onChangeValue(event: any): void {
        this.onChange(event);
    }

    onFocus(event: any): void {
        this.onTouched();
    }


    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }

    search(value: string): void {
        if (AppUtilityService.isNullOrEmpty(this.optionListSource)) {
            this.optionList = [];
            return;
        }
        if (this.optionListSource.length === 0) {
            this.optionList = [];
            return;
        }
        const that = this;
        let searchTxt = this.convertTextTimKhongDau(value);

        const filter = _.filter(this.optionListSource, function (s) {
            if (AppUtilityService.isNullOrEmpty(s.fts) === false) {
                return s.fts.indexOf(searchTxt) > -1;
            }
            let fts = that.convertTextTimKhongDau(s.displayText);
            return fts.indexOf(searchTxt) > -1;
        });
        this.optionList = filter;
    }

    convertTextTimKhongDau(s) {
        if (AppUtilityService.isNullOrEmpty(s)) {
            return s;
        }
        s = AppUtilityService.removeDau(s);
        s = s.replace(/ /g, '');
        return s;
    }


    setListOfOption(d) {
        this.optionListSource = d;
        this.optionList = d;
    }

    setValueNull() {
        this._value = null;
        this.onChangeValue(null);
    }
}
