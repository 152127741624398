<div #danhMucXaSelect>
    <nz-select nzAllowClear nzPlaceHolder="Nhập tên xã/phường để tìm kiếm"
               nzDropdownMatchSelectWidth="true"
               nzShowSearch="true"
               [(ngModel)]="inputValue"
               [id]="'select_'+now"
               (ngModelChange)="changeSelect($event)"
               [hidden]="isNull(maXa) === false"
               nzServerSearch='true'
               (nzOnSearch)="handlerSearch($event)"
               style="width:100%"
               [nzLoading]="loading"
    >
        <ng-container *ngFor="let item of listOfOption;trackBy:trackByFn">
            <nz-option [nzLabel]="getFullTenTinhHuyenXa(item)"
                       [nzValue]="item.maXa"></nz-option>
        </ng-container>

    </nz-select>


    <nz-input-group [nzSuffix]="suffixTemplate" [hidden]="isNull(maXa)" (keyup.backspace)="unSelect()">
        <input nz-input [(ngModel)]="displayXa" [readOnly]="true" [id]="'ten_xa_'+now"/>
    </nz-input-group>
    <ng-template #suffixTemplate>
        <em nz-icon nzType="close-circle" nzTheme="outline" (click)="unSelect()" style="cursor: pointer"></em>
    </ng-template>

    <ng-template #customTpl>
        <div>
            <em nz-icon nzType="file-search" nzTheme="outline" class="kt-font-danger kt-margin-r-5"></em>
            <span>Nhập tên <span
                class="font-weight-bold">xã/ phường, quận/huyện, tỉnh/thành phố</span> cách nhau bởi: '<span
                class="font-weight-bold">-</span>' để tìm kiếm chính xác hơn</span><br>
            <span class="text-primary">VD : Phường Trúc Bạch - Quận Ba Đình - Thành phố Hà Nội</span>
        </div>
    </ng-template>
</div>
