import { animate, state, style, transition, trigger,query,animateChild,group } from '@angular/animations';

export function appModuleAnimation() {
    return slideFromBottom();
}

export function accountModuleAnimation() {
    return slideFromUp();
}

export function schoolModuleAnimation(){
    return slideFromTo('right');
}

export function loginModuleAnimation(){
  return trigger('routeAnimations', [
    transition('school => login', slideTo('right') ),
    transition('login => school', slideTo('left') ),
    transition('forgot-password => login', slideTo('left') ),
    ]);
}

export function slideFromBottom() {
    return trigger('routerTransition', [
        state('void', style({ 'padding-top': '20px', opacity: '0' })),
        state('*', style({ 'padding-top': '0px', opacity: '1' })),
        transition(':enter', [
            animate('0.33s ease-out', style({ opacity: '1', 'padding-top': '0px' }))
        ])
    ]);
}

export function slideFromUp() {
    return trigger('routerTransition', [
        state('void', style({ 'margin-top': '-10px', opacity: '0' })),
        state('*', style({ 'margin-top': '0px', opacity: '1' })),
        transition(':enter', [
            animate('0.2s ease-out', style({ opacity: '1', 'margin-top': '0px' }))
        ])
    ]);
}

export function slideFromTo(direction){
    return trigger('routerTransition', [
        transition('school <=> login', [
          style({ position: 'relative' }),
          query(':enter, :leave', [
            style({
              position: 'absolute',
              top: 0,
              [direction]: 0,
              width: '100%'
            })
          ]),
          query(':enter', [
            style({ [direction]: '-100%' })
          ]),
          query(':leave', animateChild()),
          group([
            query(':leave', [
              animate('300ms ease-out', style({ [direction]: '100%' }))
            ]),
            query(':enter', [
              animate('300ms ease-out', style({ [direction]: '0%' }))
            ])
          ]),
          query(':enter', animateChild()),
        ])
    ]);
}
export function slideTo(direction) {
    const optional = { optional: true };
    return [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%'
        })
      ], optional),
      query(':enter', [
        style({ [direction]: '-100%'})
      ]),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ [direction]: '100%'}))
        ], optional),
        query(':enter', [
          animate('300ms ease-out', style({ [direction]: '0%'}))
        ])
      ]),
      // Normalize the page style... Might not be necessary

      // Required only if you have child animations on the page
       query(':leave', animateChild()),
       query(':enter', animateChild()),
    ];
  }
