import {
    Component, forwardRef, OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'vung-combo',
    template: `
        <nz-select
            [(ngModel)]="_value"
            nzAllowClear
            nzPlaceHolder="Chọn..."
            (ngModelChange)="onChangeValue($event)"
            style="width:100%"
            [nzDisabled]="_isDisabled"
            (nzFocus)="(onFocus)"
            nzShowSearch
            nzServerSearch
            (nzOnSearch)="search($event)"
        >
            <nz-option
                *ngFor="let option of optionList"
                [nzValue]="option.id"
                [nzLabel]="option.name"
            ></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => VungComboComponent),
        multi: true,
    }]
})
export class VungComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        const key = 'vung-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT id,tenVung FROM Vung`;
        let req: any = {
            token: AppUtilityService.encrypted(query)
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenVung,
                    fts: AppUtilityService.getFullTextSearch(it.tenVung)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }
}
