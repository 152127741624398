import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy, CAP_TRUONG_HOC } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'nam-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   [nzAllowClear]="allowClear"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NamComboComponent),
        multi: true
    }]
})

export class NamComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {

    @Input() allowClear? : boolean;
    constructor(private _service: UtilityServiceProxy,
                private _appSession:AppSessionService) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        if(this.allowClear == null || this.allowClear == undefined){
            this.allowClear = true;
        }

        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        var nam =  new Date().getFullYear(); //this._appSession.user.namHoc;
        let lst =[];
        let beginYear = nam - 4;
        let endyear = nam;
        for(let i= beginYear; i <= endyear;i++){
            lst.push({value:i,displayText: i+' - '+(i+1),fts:AppUtilityService.getFullTextSearch(i+' - '+(i+1))});
            lst = _.orderBy(lst,['value'],['desc']);
        }

        this.setListOfOption(lst);
    }

}
