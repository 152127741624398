import { AppSessionService } from './../../../../../shared/common/session/app-session.service';
import { Component, forwardRef, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

//Lấy danh sách lớp học của năm học hiện tại
@Component({
    selector: 'lop-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn ..."
                   [nzOpen]="autoOpen"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LopComboComponent),
        multi: true
    }]
})

export class LopComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy,private appSession: AppSessionService) {
        super();
    }
    @Input() NamHoc; //Bắt buộc truyền vào. Nếu không combo chọn thì truyền năm học hiện tại
    @Input() KhoiHocId; //Có thể truyền hoặc không
    @Input() autoOpen = false;
    @Output() getInfoLopHoc = new EventEmitter();

    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }

        this.getDataSourceFromServer(this.NamHoc, this.KhoiHocId);
    }

    onChangeValue(event: any): void {
        let f = this.optionList.find(it => it.value == event);
        this.getInfoLopHoc.emit(f);
        this.onChange(event);
    }

    handlerNamHocKhoiHocComboChange(namHoc, khoiHocId) {
        this.getDataSourceFromServer(namHoc, khoiHocId);
    }

    getDataSourceFromServer(namHoc, khoiHocId) {
        let tenantId = this.appSession.tenant.id;
        let namHocSelected = namHoc ? namHoc : this.appSession.user.namHoc;
        const key = 'lop-combo-data-' + namHocSelected;
        let query = `SELECT id, tenLop, khoiHocId
                        FROM DM_LopHoc
                        where IsActive = 1 and tenantId = @tenantId and namHoc = @namHoc ORDER BY tenLop`;
        if(khoiHocId && khoiHocId > 0){
            query = `SELECT id, tenLop, khoiHocId
                        FROM DM_LopHoc 
                        where IsActive = 1 and tenantId = @tenantId and namHoc = @namHoc and khoiHocId = @khoiHocId ORDER BY tenLop`;
        }
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                namHoc: namHocSelected,
                tenantId: tenantId,
                khoiHocId: khoiHocId
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenLop,
                    fts: AppUtilityService.getFullTextSearch(it.tenLop)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

    getItemSelected(id){
        let f = this.optionList.find(it => {
            return it.value === id;
        });
        return f;
    }
}
