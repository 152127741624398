import {
    Input,
    Component, forwardRef, OnInit, EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { Output } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
    selector: 'donvitinh-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DMThuocDonViTinhComboComponent),
        multi: true
    }]
})

export class DMThuocDonViTinhComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _httpClient: HttpClient,
        private _service: UtilityServiceProxy,
        private appSession: AppSessionService) {
        super();
    }
    @Input() thuocId;
    @Output() getInfoThuocDonViTinh = new EventEmitter();
    baseUrlService = '/api/services/app/DMThuoc/';

    ngOnInit() {
        this.setListOfOption([]);
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer(this.thuocId);
    }

    @Input()
    get tenDonViTinh() {
        return this.tenDonViTinh;
    }

    set tenDonViTinh(v: string) {
        this.setDataDonViTinhByName(this.thuocId, v);
    }

    onChangeValue(v) {
        let a = this.optionList.find(x => x.value === v);
        this.getInfoThuocDonViTinh.emit(a);
        this.onChange(v);
    }

    handlerThuocComboChange(thuocId) {
        this.getDataSourceFromServer(thuocId);
    }

    getDataSourceFromServer(thuocId) {
        if (thuocId == null)
            thuocId = 0;

        const url = AppConsts.remoteServiceBaseUrl + this.baseUrlService + 'GetListDonViTinh?thuocId=' + thuocId;

        this._httpClient
            .post<any>(url, null)
            .pipe()
            .subscribe(response => {
                let lst = _.map(response.result, (it) => {
                    return Object.assign({}, {
                        value: it.id,
                        displayText: it.tenDonViTinh,
                        soLuongQuyDoi: it.soLuongQuyDoi,
                        fts: AppUtilityService.getFullTextSearch(it.tenThuoc)
                    });
                });
                this.setListOfOption(lst);
            });
    }

    setDataDonViTinhByName(thuocId, name) {
        if (thuocId == null)
            thuocId = 0;

        const url = AppConsts.remoteServiceBaseUrl + this.baseUrlService + 'GetListDonViTinh?thuocId=' + thuocId;

        this._httpClient
            .post<any>(url, null)
            .pipe()
            .subscribe(response => {
                let lst = _.map(response.result, (it) => {
                    return Object.assign({}, {
                        value: it.id,
                        displayText: it.tenDonViTinh,
                        soLuongQuyDoi: it.soLuongQuyDoi,
                        fts: AppUtilityService.getFullTextSearch(it.tenThuoc)
                    });
                });
                this.setListOfOption(lst);
                let item = this.optionList.find(x => x.displayText == name);
                if (item != null) {
                    this._value = item.value;
                }
            });
    }
}
