<div [ngClass]="dataTable.Mobilecolumns.length > 0? 'mobile-none' :''">
    <nz-table #headerTable [nzData]="nzTable.items" [nzFrontPagination]="false"
    [nzLoading]="nzTable.loading" [nzTotal]="nzTable.totalCount"
    [(nzPageIndex)]="nzTable.pageIndex" [(nzPageSize)]="nzTable.pageSize"
    [nzPageSizeOptions]="nzTable.defaultPageOptions" nzBordered="true"
    [nzShowPagination]="showPagination"
    [nzShowQuickJumper] = "showQuickJumper"
    [nzShowSizeChanger]="false" (nzPageIndexChange)="getDataGrids()"
    (nzPageSizeChange)="this.nzTable.shouldResetPaging(true);getDataGrids()">
    <thead>
        <tr *ngIf="showHeader===true && columnsHeader.length>0" >
            <ng-container *ngFor="let col of columnsHeader;trackBy: trachByFn">
                <th [nzWidth]="getHeaderFixWidth(col)" [nzAlign]="getHeaderAlign(col)"
                    [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                    colspan="{{col.colSpan}}"
                    rowspan="{{col.rowSpan}}"
                    [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false" [innerHTML]="col.header">
                </th>
            </ng-container>
        </tr>
        <tr *ngIf="showHeader===true">
            <th [nzWidth]="'50px'" nzAlign="center" [nzLeft]="true">STT</th>
            <ng-container *ngFor="let col of columns;trackBy: trachByFn">
                <th [nzWidth]="getHeaderWidth(col)" [nzAlign]="getHeaderAlign(col)" *ngIf="col.type != 'checked'"
                    [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                    [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false" [innerHTML]="col.header">
                </th>
                <th [nzWidth]="'50px'" nzAlign="center" *ngIf="col.type == 'checked'"
                    [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                    [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false">
                    <label nz-checkbox *ngFor="let act of acCheckbox; trackBy: trachByFn" [(ngModel)]="checkboxAll"
                        (ngModelChange)="clickAllCheckbox()"></label>
                </th>
            </ng-container>
        </tr>

    </thead>
    <tbody [hidden]="nzTable.items.length === 0">
        <tr *ngFor="let dataItem of nzTable.items;index as i;trackBy: trachByFn">
            <!-- <td nzAlign="center" [nzLeft]="true">{{nzTable.getSkipCount() + i + 1}}</td> -->
            <td nzWidth="'50px'" nzAlign="center" [nzLeft]="true">{{((nzTable.pageIndex-1)*nzTable.pageSize)+i + 1}}</td>
            <td [nzAlign]="getColAlign(col)" *ngFor="let col of columns;trackBy: trachByFn"
                [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false">

                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngIf="actions.length <= 3">
                        <ng-container *ngSwitchCase="'act'">
                            <ng-container *ngFor="let act of actions; trackBy: trachByFn">
                                <a href="javascript:void(0)" class="btn btn-xxs bg-default bg-font-default margin-left-5 margin-right-5"
                                [ngStyle]="{'color': act.color}"
                                *ngIf="!act.isHide || act.isHide(dataItem) != true"
                                (click)="act.callBack(dataItem)" nz-tooltip [nzTooltipTitle]="act.name" nzTooltipPlacement="topCenter">
                                    <em nz-icon *ngIf="act.nzIcon" [nzType]="act.nzIcon" nzTheme="outline"></em>
                                    <em *ngIf="act.icon" class="{{act.icon}}" [ngStyle]="{'color': act.color}"></em>
                                </a>
                            </ng-container>

                        </ng-container>
                        <!-- <ng-container *ngSwitchCase="'checked'">
                           <div *ngIf="dataItem.isShowChecked != false">
                            <label  nz-checkbox *ngFor="let act of acCheckbox; trackBy: trachByFn"
                            [(ngModel)]="dataItem.checked" (ngModelChange)="act.callBack(dataItem)"></label>
                           </div>
                        </ng-container> -->
                    </ng-container>
                    <ng-container *ngIf="actions.length > 3">
                        <ng-container *ngSwitchCase="'act'">

                            <a href="javascript:void(0)" class="btn btn-xxs margin-left-5 margin-right-5 bg-default bg-font-default"  nz-dropdown
                                [nzDropdownMenu]="menu" nzTrigger="hover">
                                <em class="fas fa-ellipsis-h" aria-hidden="true"></em>
                            </a>

                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <ng-container *ngFor="let act of action2; trackBy: trachByFn">
                                        <li nz-menu-item (click)="act.callBack(dataItem)" *ngIf="!act.isHide || act.isHide(dataItem) != true">
                                            <em nz-icon *ngIf="act.nzIcon" [nzType]="act.nzIcon"
                                                nzTheme="outline"></em>
                                            <em *ngIf="act.icon" class="{{act.icon}}"></em>
                                            <span class="margin-left-5 font-weight-bolder">{{act.name}}</span>
                                        </li>
                                    </ng-container>
                                </ul>
                            </nz-dropdown-menu>

                            <a href="javascript:void(0)" class="btn btn-xxs bg-default bg-font-default margin-left-5 margin-right-5"
                                [ngStyle]="{'color': act.color}" *ngFor="let act of action1; trackBy: trachByFn"
                                (click)="act.callBack(dataItem)" nz-tooltip [nzTooltipTitle]="act.name" nzTooltipPlacement="topCenter">
                                <em nz-icon *ngIf="act.nzIcon" [nzType]="act.nzIcon" nzTheme="outline"></em>
                                <em *ngIf="act.icon" class="{{act.icon}}" [ngStyle]="{'color': act.color}"></em>
                            </a>


                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'checked'">
                        <div *ngIf="dataItem.isShowChecked != false">
                            <ng-container *ngFor="let act of acCheckbox; trackBy: trachByFn">
                                <label nz-checkbox *ngIf="!act.isHide || act.isHide(dataItem) != true" [(ngModel)]="dataItem.checked" (ngModelChange)="act.callBack(dataItem)"></label>
                            </ng-container>
                        </div>
                     </ng-container>

                    <ng-container *ngSwitchCase="'dataField'">
                        <span>{{dataItem[col.dataField]}}</span>
                    </ng-container>

                    <ng-container *ngSwitchCase="'render'">

                        <div *ngIf="col.render" [innerHTML]="col.render(dataItem)" (click)="addColClickEventListener(col,dataItem)"></div>

                    </ng-container>

                    <ng-container *ngSwitchCase="'renderHasTooltip'">
                        <div *ngIf="col.render && col.toolTip" [innerHTML]="col.render(dataItem)"
                        [nzTooltipTitle]="titleTemplate"
                        nzTooltipPlacement="right"
                        nz-tooltip>
                        </div>

                        <ng-template #titleTemplate>
                            <div [innerHTML]="col.toolTip(dataItem)"></div>
                          </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'template'">
                        <ng-container [ngTemplateOutlet]="columnTemplates"
                            [ngTemplateOutletContext]="{record:dataItem,dataField:col.dataField}"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'ord-custom'">
                        <ord-column-template-render [option]="col.option.ordColumn" [record]="dataItem"
                            [data]="dataItem[col.dataField]"></ord-column-template-render>
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </tbody>
</nz-table>
</div>
<div [ngClass]="dataTable.Mobilecolumns.length > 0? 'mobile-display' :''" [hidden]="dataTable.Mobilecolumns.length == 0">
    <nz-table #headerTable [nzData]="nzTable.items" [nzFrontPagination]="false"
    [nzLoading]="nzTable.loading" [nzTotal]="nzTable.totalCount"
    [(nzPageIndex)]="nzTable.pageIndex" [(nzPageSize)]="nzTable.pageSize"
    [nzPageSizeOptions]="nzTable.defaultPageOptions" nzBordered="true"
    [nzShowPagination]="showPagination"
    [nzShowQuickJumper] = "showQuickJumper"
    [nzShowSizeChanger]="false" (nzPageIndexChange)="getDataGrids()"
    (nzPageSizeChange)="this.nzTable.shouldResetPaging(true);getDataGrids()">
    <thead>
        <tr>
            <th [nzWidth]="'20px'" nzAlign="center" [nzLeft]="true">STT</th>
            <ng-container *ngFor="let col of Mobilecolumns;trackBy: trachByFn">
                <th [nzWidth]="getHeaderMobileWidth(col)" [nzAlign]="getHeaderAlign(col)" *ngIf="col.type != 'checked'"
                    [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                    [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false" [innerHTML]="col.header">
                </th>
                <th [nzWidth]="'50px'" nzAlign="center" *ngIf="col.type == 'checked'"
                    [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                    [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false">
                    <label nz-checkbox *ngFor="let act of acCheckbox; trackBy: trachByFn" [(ngModel)]="checkboxAll"
                        (ngModelChange)="clickAllCheckbox()"></label>
                </th>
            </ng-container>
        </tr>


    </thead>
    <tbody [hidden]="nzTable.items.length === 0">
        <tr *ngFor="let dataItem of nzTable.items;index as i;trackBy: trachByFn">
            <!-- <td nzAlign="center" [nzLeft]="true">{{nzTable.getSkipCount() + i + 1}}</td> -->
            <td nzWidth="'50px'" nzAlign="center" [nzLeft]="true">{{((nzTable.pageIndex-1)*nzTable.pageSize)+i + 1}}</td>
            <td [nzAlign]="getColAlign(col)" *ngFor="let col of Mobilecolumns;trackBy: trachByFn"
                [nzLeft]="col.fixedColumn && col.fixedColumn ==='left' ? true : false"
                [nzRight]="col.fixedColumn && col.fixedColumn ==='right' ? true : false">

                <ng-container [ngSwitch]="col.type">
                    <ng-container>
                        <ng-container *ngSwitchCase="'act'">

                            <a href="javascript:void(0)" class="btn btn-xxs margin-left-5 margin-right-5 bg-default bg-font-default"  nz-dropdown
                                [nzDropdownMenu]="menu" nzTrigger="hover">
                                <em class="fas fa-ellipsis-h" aria-hidden="true"></em>
                            </a>

                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <ng-container *ngFor="let act of actions; trackBy: trachByFn">
                                        <li nz-menu-item (click)="act.callBack(dataItem)" *ngIf="!act.isHide || act.isHide(dataItem) != true">
                                            <em nz-icon *ngIf="act.nzIcon" [nzType]="act.nzIcon"
                                                nzTheme="outline"></em>
                                            <em *ngIf="act.icon" class="{{act.icon}}"></em>
                                            <span class="margin-left-5 font-weight-bolder">{{act.name}}</span>
                                        </li>
                                    </ng-container>
                                </ul>
                            </nz-dropdown-menu>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'checked'">
                        <div *ngIf="dataItem.isShowChecked != false">
                            <ng-container *ngFor="let act of acCheckbox; trackBy: trachByFn">
                                <label nz-checkbox *ngIf="!act.isHide || act.isHide(dataItem) != true" [(ngModel)]="dataItem.checked" (ngModelChange)="act.callBack(dataItem)"></label>
                            </ng-container>
                        </div>
                     </ng-container>

                    <ng-container *ngSwitchCase="'dataField'">
                        <span>{{dataItem[col.dataField]}}</span>
                    </ng-container>

                    <ng-container *ngSwitchCase="'render'">

                        <div *ngIf="col.render" [innerHTML]="col.render(dataItem)" (click)="addColClickEventListener(col,dataItem)"></div>

                    </ng-container>

                    <ng-container *ngSwitchCase="'renderHasTooltip'">
                        <div *ngIf="col.render && col.toolTip" [innerHTML]="col.render(dataItem)"
                        [nzTooltipTitle]="titleTemplate"
                        nzTooltipPlacement="right"
                        nz-tooltip>
                        </div>

                        <ng-template #titleTemplate>
                            <div [innerHTML]="col.toolTip(dataItem)"></div>
                          </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'template'">
                        <ng-container [ngTemplateOutlet]="columnTemplates"
                            [ngTemplateOutletContext]="{record:dataItem,dataField:col.dataField}"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'ord-custom'">
                        <ord-column-template-render [option]="col.option.ordColumn" [record]="dataItem"
                            [data]="dataItem[col.dataField]"></ord-column-template-render>
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </tbody>
</nz-table>
</div>

<!-- <ng-template #totalTpl let-range="range" let-total>
    <span class="kt-font-primary kt-font-bold font-600">Tổng số bản ghi: {{total}}</span>
</ng-template> -->
<nz-empty [nzNotFoundContent]="nzNotFoundContent" class="kt-margin-t-5" [hidden]="this.nzTable.items.length > 0">
</nz-empty>
