import {
    Input,
    Component, forwardRef, OnInit, Output, EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'rolelevel-combo',
    template: `
        <nz-select
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="fnChangeSelect($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RoleLevelComboComponent),
        multi: true
    }]
})

export class RoleLevelComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    @Output() fnChange = new EventEmitter();
    constructor(private _service: UtilityServiceProxy, private _httpClient: HttpClient) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    fnChangeSelect(event: any): void {
        let item = this.getItemSelected(event);
        this.fnChange.emit(item)
        this.onChange(event);
    }

    getDataSourceFromServer() {
        abp.ui.setBusy();
        let url = AppConsts.remoteServiceBaseUrl + "/api/services/app/CommonEnum/GetRoleLevel";
        this._httpClient.get(url)
            .pipe(finalize(() => {
                abp.ui.clearBusy();
            })).subscribe((response) => {
                let ret = response as any;
                if (ret) {
                    let lst = _.map(ret.result, (it) => {
                        return Object.assign({}, {
                            value: it.id,
                            displayText: it.name,
                            fts: AppUtilityService.getFullTextSearch(it.name)
                        });
                    });
                    this.setListOfOption(lst);
                }
            });
    }

    getItemSelected(id) {
        let f = this.optionList.find(it => {
            return it.value === id;
        });
        return f;
    }
}
