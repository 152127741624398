import { AppSessionService } from './../../../../../shared/common/session/app-session.service';
import {
    Input,
    Component, forwardRef, OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'tiem-chung-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn nhóm tiêm chủng"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TiemChungComboComponent),
        multi: true
    }]
})

export class TiemChungComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy,
        private appSession: AppSessionService) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
        abp.event.on('selectedTiemChungOnChange',(e) => {
            this.getDataSourceFromServer();
        })
    }

    Temp: any = [];
    addToArray(array, id, lv) {
        var filter = array.filter(m => m.pId == id);
        if (filter.length > 0) {
            var sp = "";
            for (let i = 0; i < lv; i++) {
                sp += "----";
            }
            lv++;

            filter.forEach((item)=> {
                item.lv = lv;
                item.close = true;
                item.ids += "," + item.id;
                let newName = sp + item.ten;
                newName = newName != null ? newName.replace(/\n/g, '</br>') : newName;
                item.tenParent = newName;
                this.Temp.push(item);
                this.addToArray(array, item.id, lv);
            });
        }
    }
    getDataSourceFromServer() {
        const key = 'danh-muc-tiem-chung-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let tenantId = this.appSession.tenant?.id || null;
        let query = `SELECT id,ten, pId, Level FROM HS_DMTiemChung where IsActive = 1 and (tenantId is null or tenantId = @tenantId)`;
        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                tenantId: tenantId
            }
        };
        this._service.getList(req).subscribe(d => {
            let data = d.items;
            this.addToArray(data, null, 0);
            data = this.Temp;
            this.Temp = [];
            let lst = _.map(data, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenParent,
                    fts: AppUtilityService.getFullTextSearch(it.tenParent)
                });
            });
            //sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

}
