import { AppSessionService } from './../../../../shared/common/session/app-session.service';
import {Input,
    Component, forwardRef, OnInit, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { Output } from '@angular/core';

@Component({
    selector: 'nguyenlieuthucpham-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NguyenLieuThucPhamComboComponent),
        multi: true
    }]
})

export class NguyenLieuThucPhamComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy,private appSession:AppSessionService) {
        super();
    }

    listObject : any;

    @Input() loaiThucPhamEnum = 0;

    @Output() strValueChange: EventEmitter<string> = new EventEmitter<string>();


    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }

        this.getDataSourceFromServer();
    }

    onChangeValue(v) {
        this.onChange(v);
        let a = this.optionList.find(x => x.value === v);
        if(a != null){
            this.strValueChange.emit(a.displayText);
        }
        else{
            this.strValueChange.emit('');
        }
    }

    getDataSourceFromServer() {
        const tenantId = this.appSession.tenant.id;
        // const key = 'danh-muc-nguyenlieuthucpham-combo-data';
        // const cache = sessionStorage.getItem(key);
        // if (cache) {
        //     this.listObject = JSON.parse(cache);
        //     this.setListOfOption(JSON.parse(cache));
        //     return;
        // }

        let query = `SELECT id, ten FROM dm_nguyenlieuthucpham where isActive = 1 and isDeleted = 0 and tenantId = @tenantId`;

        if(this.loaiThucPhamEnum > 0){
            query = `SELECT id, ten FROM dm_nguyenlieuthucpham where isActive = 1 and isDeleted = 0 and loaiThucPhamEnum = @loaiThucPhamEnum and tenantId = @tenantId`;
        }

        let req: any = {
            token: AppUtilityService.encrypted(query),
            paramater: {
                tenantId: tenantId,
                loaiThucPhamEnum:this.loaiThucPhamEnum
            }
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.ten,
                    fts: AppUtilityService.getFullTextSearch(it.ten)
                });
            });
            this.listObject = lst;
            //sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }
}
