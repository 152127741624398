import {Input,
    Component, forwardRef, OnInit, Injector} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'khoi-hoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzOpen]="autoOpen"
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   name="khoiHocId"
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => KhoiHocComboComponent),
        multi: true
    }]
})

export class KhoiHocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {

    @Input() autoOpen = false;

    constructor(private _service: UtilityServiceProxy,
                private appSession: AppSessionService,
                )
    {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {

        var currentTenant = this.appSession.tenant;
        let query = `SELECT id, tenKhoi FROM DM_KhoiHoc where IsActive = 1`;
        if(currentTenant != null){ //Nếu tài khoản trường học thì lọc các khối của trường đó
            let capTruongHocArr = JSON.parse(currentTenant.capTruongEnum);
            if(capTruongHocArr){
                query += ` and CapHocEnum in (${capTruongHocArr.join(',')})`;
            }
        }
        let req: any = {
            token: AppUtilityService.encrypted(query),
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id,
                    displayText: it.tenKhoi,
                    fts: AppUtilityService.getFullTextSearch(it.tenKhoi)
                });
            });
            this.setListOfOption(lst);
        });
    }

}
