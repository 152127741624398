import { Router } from '@angular/router';
import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'thanghoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn tháng"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ThangHocComboComponent),
        multi: true
    }]
})

export class ThangHocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    @Input() IsChangeTrigger :boolean= false;
    constructor(private _service: UtilityServiceProxy,private router:Router) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        let dateNow = new Date();
        const key = 'thanghoc-combo-data'+dateNow.getFullYear();
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let lst =[
            {value: 8,displayText:'Tháng 8/'+dateNow.getFullYear(),fts:AppUtilityService.getFullTextSearch('Tháng 8/'+dateNow.getFullYear())},
            {value: 9,displayText:'Tháng 9/'+dateNow.getFullYear(),fts:AppUtilityService.getFullTextSearch('Tháng 9/'+dateNow.getFullYear())},
            {value: 10,displayText:'Tháng 10/'+dateNow.getFullYear(),fts:AppUtilityService.getFullTextSearch('Tháng 10/'+dateNow.getFullYear())},
            {value: 11,displayText:'Tháng 11/'+dateNow.getFullYear(),fts:AppUtilityService.getFullTextSearch('Tháng 11/'+dateNow.getFullYear())},
            {value: 12,displayText:'Tháng 12/'+dateNow.getFullYear(),fts:AppUtilityService.getFullTextSearch('Tháng 12/'+dateNow.getFullYear())},
            {value: 1,displayText:'Tháng 1/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 1/'+(dateNow.getFullYear()+1))},
            {value: 2,displayText:'Tháng 2/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 2/'+(dateNow.getFullYear()+1))},
            {value: 3,displayText:'Tháng 3/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 3/'+(dateNow.getFullYear()+1))},
            {value: 4,displayText:'Tháng 4/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 4/'+(dateNow.getFullYear()+1))},
            {value: 5,displayText:'Tháng 5/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 5/'+(dateNow.getFullYear()+1))},
            {value: 6,displayText:'Tháng 6/'+(dateNow.getFullYear()+1),fts:AppUtilityService.getFullTextSearch('Tháng 6/'+(dateNow.getFullYear()+1))},
        ];

        sessionStorage.setItem(key, JSON.stringify(lst));
        this.setListOfOption(lst);


    }



}
