import {
    Component, OnInit, ElementRef, EventEmitter, Injector, Output, ViewChild,
    KeyValueDiffer, Input, AfterViewInit
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import { delay, finalize } from 'rxjs/operators';
import {
    DynamicFormPageComponent,
    OrdFormItem
} from '@main_pages/_base/form-custom/dynamic-form/dynamic-form-page.component';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import * as $ from 'jquery';
import { DragDrop } from '@angular/cdk/drag-drop';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ResponseCodeStatus } from '@shared/service-proxies/service-proxies';
import { AppMainComponentBase } from '@main_pages/_base/app-main.component';
import { Router } from '@angular/router';
@Component({
    selector: 'redirect-tao-lop-hoc-modal',
    templateUrl: './redirect-tao-lop-hoc-modal.component.html'
})

export class RedirectTaoLopHocModalComponent extends AppMainComponentBase implements OnInit{
    baseUrlService = '/api/services/app/DMLopHoc/';
    @Input() dataItem: any;
    isOnlyView = false;
    validateForm: FormGroup;
    namhoc = this.appSession.user.namHoc;

    constructor(
        private _httpClient: HttpClient,
        injector: Injector,
        private modal: NzModalRef,
        private fb: FormBuilder,
        private dragDrop: DragDrop,
        private route:Router
    ) { 
        super(injector);
    }

    ngOnInit(): void {
    }

    redirect(): void {
        this.close();
        this.route.navigate([`/app/pages/cau-hinh-thong-tin/lop-hoc/${true}`]);
    }

    close(): void {
        this.modal.destroy();
    }

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    ngAfterViewInit(): void {
        this.fullScreenClick();
    }
}
