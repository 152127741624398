import { AppSessionService } from './../../../../../shared/common/session/app-session.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConsts } from './../../../../../shared/AppConsts';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as $ from 'jquery';
import {fromEvent} from '@node_modules/rxjs';
import {debounceTime} from '@node_modules/rxjs/internal/operators';
import {NzConfigService} from '@node_modules/ng-zorro-antd';
import {DanhMucXaServiceProxy, UtilityServiceProxy} from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'hoc-sinh-auto-complete',
    encapsulation: ViewEncapsulation.None,
    template: `
    <div *ngIf="!isClicked">
      <nz-input-group [nzSuffix]="suffixIcon">
      <input placeholder="Nhập tên học sinh để tìm kiếm ..." nz-input [(ngModel)]="inputValue" (ngModelChange)="onChange($event)" [nzAutocomplete]="auto" />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let group of filteredOptions" [nzValue]="group.id" [nzLabel]="group.name" (click)="click(group)">{{group.name}} ({{group.ngaySinh | date:'dd/MM/yyyy'}})</nz-auto-option>
      </nz-autocomplete>
    </div>
    <nz-input-group nzSearch *ngIf="isClicked" [nzAddOnAfter]="suffixIconButton">
        <input nz-input [(ngModel)]="inputLabel" readonly/>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="unSelect()" nzDanger><i nz-icon nzType="close"></i></button>
        </ng-template>
    </nz-input-group>

  `,

})
export class HocSinhAutoCompleteComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() inputValue:string;
    @Output() inputValueChange = new EventEmitter();

    @Input() inputLabel:string;
    @Output() inputLabelChange = new EventEmitter();

    @Output() getAllData = new EventEmitter();

    isClicked:boolean = false;
    filteredOptions: any[] = [];


    constructor(private _http:HttpClient,
        private _appSession:AppSessionService){

    }

    ngOnInit(){
        this.getDataSource('');
    }

    onChange(value: string): void {
        this.getDataSource(value);
    }

    getDataSource(textSearch:string){
        const url = AppConsts.remoteServiceBaseUrl + '/api/services/app/HSHocSinh/HocSinhAutoComplete';

        const params = new HttpParams({
            fromObject:{
                textSearch:textSearch,
                tenantId:this._appSession.tenantId + ''
            }
        })

        this._http.get<any>(url,{params:params}).subscribe(response =>{
            this.filteredOptions = response.result.slice(0,10);
        })
    }

    isNullOrEmpty(value) {
        return AppUtilityService.isNullOrEmpty(value);
    }

    unSelect(){
        this.isClicked = false;
        this.inputValue = null;
        this.inputLabel = '';
        this.inputLabelChange.emit('');
        this.inputValueChange.emit(this.inputValue);
        this.getAllData.emit(null);
        this.getDataSource('');
    }

    click(dataItem){
        this.getAllData.emit(dataItem);
        this.inputValueChange.emit(this.inputValue);
        this.inputLabel = dataItem.name;
        this.inputLabelChange.emit(dataItem.name);
        this.isClicked = true;
    }

    ngOnChanges(){

    }

    ngAfterViewInit(){
    }

}
