import {AppMenuItem} from '@app/shared/layout/nav/app-menu-item';

export const AdministrationMenuItem = [
    new AppMenuItem('Administration', '', 'far fa-user-circle', '../../../assets/common/images/image-icon/account.svg', '/app/admin/roles', [], [
        new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'fas fa-user-shield', '', '/app/admin/organization-units'),
        new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fas fa-suitcase', '', '/app/admin/roles'),
        new AppMenuItem('Users', 'Pages.Administration.Users', 'fas fa-user-friends', '', '/app/admin/users'),
        //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'fas fa-language', '', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
        new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'fas fa-envelope-open-text', '', '/app/admin/auditLogs'),
        new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'fas fa-user-clock', '', '/app/admin/maintenance'),
        new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'fas fa-sync', '', '/app/admin/subscription-management'),
        new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'fas fa-code-branch', '', '/app/admin/ui-customization'),
        new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'fas fa-globe', '', '/app/admin/webhook-subscriptions'),
        new AppMenuItem('DynamicParameters', '', 'flaticon-interface-8', '', '', [], [
            new AppMenuItem('Definitions', 'Pages.Administration.DynamicParameters', '', '', '/app/admin/dynamic-parameter'),
            new AppMenuItem('EntityDynamicParameters', 'Pages.Administration.EntityDynamicParameters', '', '','/app/admin/entity-dynamic-parameter'),
        ]),
        new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'fas fa-cogs', '', '/app/admin/hostSettings'),
        new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'fas fa-cogs', '', '/app/admin/tenantSettings'),
        new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'fas fa-server', '', '/app/admin/demo-ui-components')
    ])
];

