import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as $ from 'jquery';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { LopComboComponent } from './lop-combo.component';

@Component({
    selector: 'nam-lop-select',
    template: `
        <div nz-row [nzGutter]="[16, 24]">
            <div nz-col class="gutter-row" [nzSpan]="12" id="{{now + '_nam'}}">
                <nz-form-label [nzRequired]="required">Năm học</nz-form-label>
                <nam-combo [(ngModel)]="namHoc" [disabled]="disabled"
                            (ngModelChange)="lopCombo.handlerNamHocKhoiHocComboChange($event, null);namHocChange.emit($event);lopHocIdChange.emit(null);"></nam-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span *ngIf="!namHoc || namHoc === null || namHoc ===''">{{err}}</span>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="12" id="{{now + '_lop'}}">
                <nz-form-label [nzRequired]="required">Lớp học</nz-form-label>
                <lop-combo #lopCombo [(ngModel)]="lopHocId" [disabled]="disabled"
                             (ngModelChange)="lopHocIdChange.emit($event)"></lop-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span *ngIf="!lopHocId || lopHocId === null || lopHocId ===''">{{err}}</span>
                </div>
            </div>
        </div>
    `,
    styles: [`.ant-form-item-label {
        padding: 6px 0px !important;
    }`]
})
export class NamLopSelectComponent implements OnChanges, AfterViewInit {
    @Input() disabled;
    @Input() namHoc = '';
    @Output() namHocChange = new EventEmitter();
    @Input() lopHocId = '';
    @Output() lopHocIdChange = new EventEmitter();
    @Input() required = false;
    submitted = false;
    err = 'Không để trống trường này!';
    @ViewChild('lopCombo') lopCombo: LopComboComponent;
    now = Number(new Date());

    constructor() {
        abp.event.on('nam-lop-select@validate', (submitted) => {
            if (this.required === true) {
                this.submitted = submitted;
            }
        });
        abp.event.on('nam-lop-select@focus', () => {
            if (AppUtilityService.isNullOrEmpty(this.namHoc)) {
                $('#' + this.now + '_nam').find('.ant-select-selection-search-input').focus();
            }
            if (AppUtilityService.isNullOrEmpty(this.lopHocId)) {
                $('#' + this.now + '_lop').find('.ant-select-selection-search-input').focus();
            }

        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.namHoc && this.lopCombo) {
            this.lopCombo.getDataSourceFromServer(changes.namHoc.currentValue, null);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.lopCombo.getDataSourceFromServer(this.namHoc, null);
        });
    }
}
