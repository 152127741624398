import {Input, Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'dan-toc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn dân tộc"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DanTocComboComponent),
        multi: true
    }]
})

export class DanTocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        const key = 'danh-muc-dantoc-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let query = `SELECT id,tenDanToc FROM DM_DanToc`;
        let req: any = {
            token: AppUtilityService.encrypted(query)
        };
        this._service.getList(req).subscribe(d => {
            let lst = _.map(d.items, (it) => {
                return Object.assign({}, {
                    value: it.id+'',
                    displayText: it.tenDanToc,
                    fts: AppUtilityService.getFullTextSearch(it.tenDanToc)
                });
            });
            sessionStorage.setItem(key, JSON.stringify(lst));
            this.setListOfOption(lst);
        });
    }

}
