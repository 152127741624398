import {NgModule} from '@angular/core';
import {NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AppRouteGuard} from './shared/common/auth/auth-route-guard';
import {NotificationsComponent} from './shared/layout/notifications/notifications.component';
import {NgxSpinnerService} from 'ngx-spinner';
import { NotFoundComponent } from './shared/layout/notfound/not-found.component';
import { DisconnectedComponent } from './shared/layout/disconnected/disconnected.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            {path: 'notifications', component: NotificationsComponent},
                            {path: '', redirectTo: '/app/main/home', pathMatch: 'full'}
                        ]
                    },
                    {
                        path: 'notfound', component: NotFoundComponent
                    },
                    {
                        path: 'disconnected', component: DisconnectedComponent
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then(m => m.MainModule), //Lazy load main module
                        data: {preload: true}
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: {preload: true},
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'pages',
                        loadChildren: () => import('app-main/app-main.module').then(m => m.AppMainModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: '**', redirectTo: 'notfound'
                    }
                ]
            }
        ])
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(
        private router: Router,
        private spinnerService: NgxSpinnerService
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }

            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
}
