import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as $ from 'jquery';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { APIHuyenComboComponent } from './api-huyen-combo.component';

@Component({
    selector: 'api-tinh-huyen-select',
    template: `
        <div nz-row [nzGutter]="[16, 24]">
            <div nz-col class="gutter-row" [nzSpan]="12" id="{{now + '_tinh'}}">
                <nz-form-label [nzRequired]="required">Tỉnh/ thành phố</nz-form-label>
                <api-tinh-combo [(ngModel)]="maTinh" [disabled]="disabled"
                            (ngModelChange)="huyenCombo.handlerTinhComboChange($event);maTinhChange.emit($event)"></api-tinh-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span *ngIf="!maTinh || maTinh === null || maTinh ===''">{{err}}</span>
                </div>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="12" id="{{now + '_huyen'}}">
                <nz-form-label [nzRequired]="required">Quận/ huyện</nz-form-label>
                <api-huyen-combo #huyenCombo [(ngModel)]="maHuyen" [disabled]="disabled"
                             (ngModelChange)="maHuyenChange.emit($event)"></api-huyen-combo>
                <div class="form-control-err"
                     *ngIf="submitted">
                    <span *ngIf="!maHuyen || maHuyen === null || maHuyen ===''">{{err}}</span>
                </div>
            </div>
        </div>
    `,
    styles: [`.ant-form-item-label {
        padding: 6px 0px !important;
    }`]
})
export class APITinhHuyenSelectComponent implements OnChanges, AfterViewInit {
    @Input() disabled;
    @Input() maTinh = '';
    @Output() maTinhChange = new EventEmitter();
    @Input() maHuyen = '';
    @Output() maHuyenChange = new EventEmitter();
    @Input() required = false;
    submitted = false;
    err = 'Không để trống trường này!';
    @ViewChild('huyenCombo') huyenCombo: APIHuyenComboComponent;
    now = Number(new Date());

    constructor() {
        abp.event.on('tinh-huyen-select@validate', (submitted) => {
            if (this.required === true) {
                this.submitted = submitted;
            }
        });
        abp.event.on('tinh-huyen-select@focus', () => {
            if (AppUtilityService.isNullOrEmpty(this.maTinh)) {
                $('#' + this.now + '_tinh').find('.ant-select-selection-search-input').focus();
            }
            if (AppUtilityService.isNullOrEmpty(this.maHuyen)) {
                $('#' + this.now + '_huyen').find('.ant-select-selection-search-input').focus();
            }

        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maTinh && this.huyenCombo) {
            this.huyenCombo.getDataSourceFromServer(changes.maTinh.currentValue);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.huyenCombo.getDataSourceFromServer(this.maTinh);
        });
    }
}
