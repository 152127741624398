import {AbpHttpConfigurationService, AbpHttpInterceptor, RefreshTokenService} from 'abp-ng2-module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import {ZeroRefreshTokenService} from '@account/auth/zero-refresh-token.service';
import {ZeroTemplateHttpConfigurationService} from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.DanhMucQuocGiaServiceProxy,
        ApiServiceProxies.DMTonGiaoServiceProxy,
        ApiServiceProxies.DMDanTocServiceProxy,
        ApiServiceProxies.UtilityServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicParameterServiceProxy,
        ApiServiceProxies.DynamicEntityParameterDefinitionServiceProxy,
        ApiServiceProxies.EntityDynamicParameterServiceProxy,
        ApiServiceProxies.DynamicParameterValueServiceProxy,
        ApiServiceProxies.EntityDynamicParameterValueServiceProxy,
        ApiServiceProxies.DiemTruongServiceProxy,
        ApiServiceProxies.QuanLyTaiKhoanServiceProxy,
        ApiServiceProxies.HSBaoCaoYTeServiceProxy,
        ApiServiceProxies.HSHocSinhServiceProxy,
        ApiServiceProxies.ApiHsskServiceProxy,
        ApiServiceProxies.CommonEnumServiceProxy,
        ApiServiceProxies.DemoDataServiceProxy,
        ApiServiceProxies.DMLopHocServiceProxy,
        ApiServiceProxies.DanhMucHFAServiceProxy,
        ApiServiceProxies.DanhMucWFAServiceProxy,
        ApiServiceProxies.TaiKhoanPhuHuynhServiceProxy,
        ApiServiceProxies.NguyCoLayNhiemServiceProxy,
        ApiServiceProxies.DanhSachKhaiBaoServiceProxy,
        ApiServiceProxies.TongQuanCovidHostServiceProxy,
        ApiServiceProxies.DanhSachKhaiBaoHostServiceProxy,
        ApiServiceProxies.TheoDoiThuocTaiTruongServiceProxy,
        ApiServiceProxies.PhieuXuatThuocServiceProxy,
        ApiServiceProxies.BanHangServiceProxy,
        ApiServiceProxies.CapNhatChiSoSucKhoeServiceProxy,
        ApiServiceProxies.CapNhatChiSoSucKhoeChiTietServiceProxy,
        ApiServiceProxies.CapNhatHangNgayServiceProxy,
        ApiServiceProxies.ApiHsskServiceProxy,
        ApiServiceProxies.DanhSachTruongHocServiceProxy,
        ApiServiceProxies.KhamChuyenKhoaServiceProxy,
        ApiServiceProxies.BangTongHopKhamChuyenKhoaServiceProxy,
        ApiServiceProxies.TaiKhoanBacSiChuyenKhoaServiceProxy,
        ApiServiceProxies.DMLopHocServiceProxy,
        ApiServiceProxies.VeSinhHocDuongServiceProxy,
        ApiServiceProxies.SoSucKhoeHocSinhServiceProxy,
        ApiServiceProxies.ThongTinTruongHocServiceProxy,
        ApiServiceProxies.KhaiBaoYTeCovidServiceProxy,
        ApiServiceProxies.NoiDungThongBaoServiceProxy,
        ApiServiceProxies.BanHangServiceProxy,
        ApiServiceProxies.DanhMucVungServiceProxy,
        ApiServiceProxies.TheoDoiSucKhoeTongHopServiceProxy,
        ApiServiceProxies.CanBoServiceProxy,
        ApiServiceProxies.CauHinhHocSinhServiceProxy,
        ApiServiceProxies.BaoHiemYTeServiceProxy,
        ApiServiceProxies.DanhMucNoiKhamChuaBenhBanDauServiceProxy,
        ApiServiceProxies.TheoDoiBatThuongSKCBServiceProxy,
        ApiServiceProxies.LienThongHoSoSucKhoeServiceProxy,
        ApiServiceProxies.TaiKhoanKinhDoanhServiceProxy,
        ApiServiceProxies.DanhSachThueBaoServiceProxy,
        ApiServiceProxies.BaoCaoKinhDoanhServiceProxy,
        ApiServiceProxies.DashBoardServiceProxy,
        ApiServiceProxies.CB_TheoDoiTheLucServiceProxy,
        ApiServiceProxies.LapKeHoachAttpServiceProxy,
        ApiServiceProxies.ThongTinLienLacServiceProxy,
        ApiServiceProxies.BaoHiemYTeHostServiceProxy,
        ApiServiceProxies.SoCuuCapCuuCanBoServiceProxy,
        ApiServiceProxies.SoCuuCapCuuHocSinhServiceProxy,
        ApiServiceProxies.TheLucHocSinhHostServiceProxy,
        ApiServiceProxies.TheLucCanBoHostServiceProxy,
        ApiServiceProxies.HostBaoCaoYTeServiceProxy,
        ApiServiceProxies.HostKeHoachCongTacYTeServiceProxy,
        ApiServiceProxies.HostDanhGiaCongTacYTeServiceProxy,
        ApiServiceProxies.KetQuaDanhGiaYTeServiceProxy,
        ApiServiceProxies.DongBoDuLieuGiaoDucServiceProxy,
        ApiServiceProxies.DongBoDuLieuSmasServiceProxy,
        ApiServiceProxies.DanhMucKhuVucTruongHocServiceProxy,
        ApiServiceProxies.LichSuGiaoTiepGoiTinServiceProxy,
        ApiServiceProxies.DMThuocServiceProxy,
        ApiServiceProxies.SSOAuthServiceProxy,
        ApiServiceProxies.DanhMucTruongHocSSOServiceProxy,
        {provide: RefreshTokenService, useClass: ZeroRefreshTokenService},
        {provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService},
        {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true}
    ]
})
export class ServiceProxyModule {
}
