import { Injector, Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './default-brand.component.html',
    selector: 'default-brand',
    encapsulation: ViewEncapsulation.None
})
export class DefaultBrandComponent extends AppComponentBase implements OnInit{

    // defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/logo-light.png';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    toggleCheck = true;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,

    ) {
        super(injector);
    }

    toggleLeftAside(): void {
        this.document.body.classList.toggle('aside-minimize');
        this.triggerAsideToggleClickEvent();
        this.toggleCheck = !this.toggleCheck;
        sessionStorage.setItem('aside-minimize', JSON.stringify(this.toggleCheck));
    }

    asideMenu() {
        setTimeout(() => {
            var aside = sessionStorage.getItem('aside-minimize');
            if (aside == 'false') {
                this.document.body.classList.add('aside-minimize');

            }
        }, 100);
    }

    ngOnInit(): void {
        sessionStorage.setItem('aside-minimize', JSON.stringify(this.toggleCheck));
        
    }

    triggerAsideToggleClickEvent(): void {
        abp.event.trigger('app.kt_aside_toggler.onClick');
    }
}
