<div appBsModal #linkAccountModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">

        <div class="modal-content">

            <form *ngIf="active" #linkAccountForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"LinkNewAccount" | localize}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label for="TenancyName">{{"TenancyName" | localize}}</label>
                        <input id="TenancyName" #tenancyNameInput="ngModel" type="text" name="TenancyName" class="form-control" [ngClass]="{'edited': linkUser.tenancyName}" [(ngModel)]="linkUser.tenancyName" maxlength="64">
                    </div>

                    <div class="form-group">
                        <label for="UserName">{{"UserName" | localize}} *</label>
                        <input id="UserName" #usernameOrEmailAddressInput="ngModel" class="form-control" type="text" name="UserName" [ngClass]="{'edited': linkUser.usernameOrEmailAddress}" [(ngModel)]="linkUser.usernameOrEmailAddress" required maxlength="256">
                        <validation-messages [formCtrl]="usernameOrEmailAddressInput"></validation-messages>
                    </div>

                    <div class="form-group">
                        <label for="Password">{{"Password" | localize}} *</label>
                        <input id="Password" #passwordInput="ngModel" type="password" [ngClass]="{'edited': linkUser.password}" name="Password" class="form-control" [(ngModel)]="linkUser.password" required maxlength="128">
                        <validation-messages [formCtrl]="passwordInput"></validation-messages>
                    </div>
                </div>

                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary font-weight-bold" [disabled]="!linkAccountForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><em class="fa fa-save"></em> <span>{{"Save" | localize}}</span></button>
                </div>

            </form>

        </div>
    </div>
</div>
