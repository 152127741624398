import {NgModule} from '@angular/core';
import {NgZorroAntdModule, NZ_I18N, vi_VN, NZ_ICONS, en_GB, NZ_DATE_LOCALE} from 'ng-zorro-antd';
import { vi as viDate } from 'date-fns/locale';

/** config angular i18n **/
import {registerLocaleData} from '@angular/common';
import vi from '@angular/common/locales/vi';

registerLocaleData(vi);
/** ICON **/
import * as AllIcons from '@ant-design/icons-angular/icons';
import {IconDefinition} from '@ant-design/icons-angular';

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

@NgModule({
    declarations: [],
    imports: [
        /** import ng-zorro-antd root module，you should import NgZorroAntdModule and avoid importing sub modules directly **/
        NgZorroAntdModule
    ],
    exports: [
        NgZorroAntdModule
    ],
    providers: [
        {provide: NZ_I18N, useValue: vi_VN},
        {provide: NZ_ICONS, useValue: icons},
        { provide: NZ_DATE_LOCALE, useValue: viDate },
        ],
})
export class AntDesignModule {
}
