import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonEnumServiceProxy, UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'cap-hoc-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   [nzSuffixIcon]="prefixTemplateUser"
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
            <ng-template #prefixTemplateUser><em class="fas fa-caret-down" aria-hidden="true"></em></ng-template>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CapHocComboComponent),
        multi: true
    }]
})

export class CapHocComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _commonEnumService: CommonEnumServiceProxy) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        const key = 'danh-muc-cap-hoc-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        
        this._commonEnumService.getEnumCapTruongHoc().subscribe(result =>{
            if(result){
                let lst = _.map(result, (it) => {
                    return Object.assign({}, {
                        value: it.id+'',
                        displayText: it.name,
                        fts: AppUtilityService.getFullTextSearch(it.name)
                    });
                });
                sessionStorage.setItem(key, JSON.stringify(lst));
                this.setListOfOption(lst);
            }
        }) 
    }

}
