import { Component, Injector, OnInit, ViewChild, Injectable } from '@angular/core';

import { OrdActionGrid, OrdColumn, OrdColumnHeader, OrdDataTableComponent } from '@main_pages/_base/pages-custom/ord-data-table/ord-data-table.component';
import { AppComponentBase } from '@shared/common/app-component-base';
@Component({
    template: ''
})
export abstract class AppMainComponentBase extends AppComponentBase {

    @ViewChild('dataTableView') dataTableView: OrdDataTableComponent;
    ordDataTable: {
        searchData: any;
        columns: OrdColumn[],
        Mobilecolumns: OrdColumn[],
        columnsHeader: OrdColumnHeader[],
        actions: OrdActionGrid[],
        acCheckbox: OrdActionGrid[],
        pagingListUrl: string,
        reload: any;
    };

    constructor(injector: Injector) {
        super(injector);

        this.ordDataTable = {
            searchData: {},
            columns: [],
            Mobilecolumns: [],
            columnsHeader:[],
            actions: [],
            acCheckbox: [],
            pagingListUrl: '',
            reload: function () {
                abp.event.trigger(this.pagingListUrl + 'refreshList');
            }
        };
    }
}
