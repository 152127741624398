<div>
    <form id="FormThongTin">
        <h3>Thông tin trường học</h3>
        <div class="row">
            <div class="col-md-12 gutter-row">
                <nz-form-label nzFor="tenTruongHoc" nzRequired>Tên trường học</nz-form-label>
                <nz-form-control>
                    <nz-input-group [nzSuffix]="inputTenTruongHoc">
                        <input nz-input placeholder="Nhập ..." [(ngModel)]="thongTinCapNhat.tenTruongHoc" name="tenTruongHoc"/>
                    </nz-input-group>
                    <ng-template #inputTenTruongHoc>
                        <i
                          nz-icon
                          class="ant-input-clear-icon"
                          nzTheme="fill"
                          nzType="close-circle"
                          *ngIf="thongTinCapNhat.tenTruongHoc"
                          (click)="thongTinCapNhat.tenTruongHoc = null"
                        ></i>
                    </ng-template>
                    <validation-custom [sModel]="thongTinCapNhat.tenTruongHoc"></validation-custom>
                </nz-form-control>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <tinh-huyen-xa-combo-full [(maTinh)]="thongTinCapNhat.maTinh"
                                          [(maHuyen)]="thongTinCapNhat.maHuyen"
                                          [required]="true"
                                          [disabledTinh]="disableTinh"
                                          [(maXa)]="thongTinCapNhat.maXa"></tinh-huyen-xa-combo-full>
                <validation-custom [hidden]="true" [sModel]="thongTinCapNhat.maTinh"></validation-custom>
                <validation-custom [hidden]="true" [sModel]="thongTinCapNhat.maHuyen"></validation-custom>
                <validation-custom [hidden]="true" [sModel]="thongTinCapNhat.maXa"></validation-custom>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 gutter-row">
                <nz-form-label nzFor="diaChi">Số nhà, đường, tổ, thôn....</nz-form-label>
                <nz-form-control>
                    <nz-input-group [nzSuffix]="inputDiaChi">
                        <input nz-input placeholder="Nhập ..." [(ngModel)]="thongTinCapNhat.diaChi" name="diaChi"/>
                    </nz-input-group>
                    <ng-template #inputDiaChi>
                        <i nz-icon
                          class="ant-input-clear-icon"
                          nzTheme="fill"
                          nzType="close-circle"
                          *ngIf="thongTinCapNhat.diaChi"
                          (click)="thongTinCapNhat.diaChi = null"></i>
                    </ng-template>
                </nz-form-control>
            </div>
        </div>
        <hr/>
        <h3>Thông tin y tá phụ trách tại trường học</h3>
        <div class="row">
            <div class="col-md-4 gutter-row">
                <nz-form-label nzFor="hoTenNguoiPhuTrach" nzRequired>Họ và tên</nz-form-label>
                <nz-form-control>
                    <nz-input-group [nzSuffix]="inputHoTenNguoiPhuTrach">
                        <input nz-input placeholder="Nhập ..." [(ngModel)]="thongTinCapNhat.hoTenNguoiPhuTrach" name="hoTenNguoiPhuTrach"/>
                    </nz-input-group>
                    <ng-template #inputHoTenNguoiPhuTrach>
                        <i nz-icon
                          class="ant-input-clear-icon"
                          nzTheme="fill"
                          nzType="close-circle"
                          *ngIf="thongTinCapNhat.hoTenNguoiPhuTrach"
                          (click)="thongTinCapNhat.hoTenNguoiPhuTrach = null"></i>
                    </ng-template>
                    <validation-custom [sModel]="thongTinCapNhat.hoTenNguoiPhuTrach"></validation-custom>
                </nz-form-control>
            </div>
            <div class="col-md-4 gutter-row">
                <nz-form-label nzFor="soDienThoaiNguoiPhuTrach" nzRequired>Số điện thoại</nz-form-label>
                <nz-form-control>
                    <nz-input-group [nzSuffix]="inputSoDienThoaiNguoiPhuTrach">
                        <input nz-input placeholder="Nhập ..." [(ngModel)]="thongTinCapNhat.soDienThoaiNguoiPhuTrach" name="soDienThoaiNguoiPhuTrach"/>
                    </nz-input-group>
                    <ng-template #inputSoDienThoaiNguoiPhuTrach>
                    <i  nz-icon
                        class="ant-input-clear-icon"
                        nzTheme="fill"
                        nzType="close-circle"
                        *ngIf="thongTinCapNhat.soDienThoaiNguoiPhuTrach"
                        (click)="thongTinCapNhat.soDienThoaiNguoiPhuTrach = null"></i>
                    </ng-template>
                    <validation-custom [sModel]="thongTinCapNhat.soDienThoaiNguoiPhuTrach"></validation-custom>
                </nz-form-control>
            </div>
            <div class="col-md-4 gutter-row">
                <nz-form-label nzFor="emailNguoiPhuTrach" nzRequired>Email</nz-form-label>
                <nz-form-control>
                    <nz-input-group [nzSuffix]="inputEmailNguoiPhuTrach">
                        <input nz-input placeholder="Nhập ..." [(ngModel)]="thongTinCapNhat.emailNguoiPhuTrach" name="emailNguoiPhuTrach"/>
                    </nz-input-group>
                    <ng-template #inputEmailNguoiPhuTrach>
                        <i
                          nz-icon
                          class="ant-input-clear-icon"
                          nzTheme="fill"
                          nzType="close-circle"
                          *ngIf="thongTinCapNhat.emailNguoiPhuTrach"
                          (click)="thongTinCapNhat.emailNguoiPhuTrach = null"></i>
                    </ng-template>
                    <validation-custom [sModel]="thongTinCapNhat.emailNguoiPhuTrach"></validation-custom>
                </nz-form-control>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 gutter-row">
                <p class="mb-0"><em><em class="fas fa-info-circle"></em> Chú ý: </em></p>
                <ul>
                    <li>
                        <em>Các thông tin có dấu <span class="font-red">*</span> là bắt buộc nhập.</em>
                    </li>
                    <li>
                        <em>Bạn có thể dùng email để lấy lại mật khẩu khi bạn đã quên.</em>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <div *nzModalFooter>
        <button class="btn bg-green-ythd bg-font-green-ythd" (click)="save()"><em class="fas fa-save"></em> Lưu thông tin</button>
    </div>
</div>

