<div appBsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MySettings" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="user" id="FormThongTinUser">
                    <div class="gutter-row">
                        <nz-form-label nzFor="Surname" nzRequired>{{"Surname" | localize}} </nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="inputSurname">
                                <input nz-input placeholder="Nhập ..." [(ngModel)]="user.surname" id="Surname" name="surname" maxlength="64"/>
                            </nz-input-group>
                            <ng-template #inputSurname>
                                <i
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="user.surname"
                                  (click)="user.surname = null"
                                ></i>
                            </ng-template>
                            <validation-custom [sModel]="user.surname"></validation-custom>
                        </nz-form-control>
                    </div>

                    <div class="gutter-row">
                        <nz-form-label nzFor="Name" nzRequired>{{"Name" | localize}} </nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="inputName">
                                <input nz-input placeholder="Nhập ..." [(ngModel)]="user.name" id="Name" name="name" maxlength="64"/>
                            </nz-input-group>
                            <ng-template #inputName>
                                <i
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="user.name"
                                  (click)="user.name = null"
                                ></i>
                            </ng-template>
                            <validation-custom [sModel]="user.name"></validation-custom>
                        </nz-form-control>
                    </div>

                    <div class="gutter-row" [hidden]="'Pages.KinhDoanh' | permission">
                        <nz-form-label nzFor="EmailAddress" nzRequired>{{"EmailAddress" | localize}} </nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="inputEmailAddress">
                                <input nz-input placeholder="Nhập ..." [(ngModel)]="user.emailAddress" name="emailAddress" maxlength="256"/>
                            </nz-input-group>
                            <ng-template #inputEmailAddress>
                                <i
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="user.emailAddress"
                                  (click)="user.emailAddress = null"
                                ></i>
                            </ng-template>
                            <validation-custom [sType]="2" [sModel]="user.emailAddress"></validation-custom>
                        </nz-form-control>
                    </div>

                    <div class="gutter-row">
                        <nz-form-label nzFor="UserName" nzRequired>{{"UserName" | localize}} </nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="inputUserName">
                                <input nz-input placeholder="Nhập ..." [disabled]="!canChangeUserName" [(ngModel)]="user.userName" maxlength="256" name="userName"/>
                            </nz-input-group>
                            <ng-template #inputUserName>
                                <i
                                  nz-icon
                                  class="ant-input-clear-icon"
                                  nzTheme="fill"
                                  nzType="close-circle"
                                  *ngIf="user.userName && canChangeUserName"
                                  (click)="user.userName = null"
                                ></i>
                            </ng-template>
                            <span class="form-text text-muted" *ngIf="!canChangeUserName">{{"CanNotChangeAdminUserName" | localize}}</span>
                            <validation-custom [sModel]="user.userName"></validation-custom>
                        </nz-form-control>
                    </div>
                    
                    <div class="gutter-row">
                        <p class="mb-0"><em><em class="fas fa-info-circle"></em> Chú ý: Các thông tin có dấu <span class="font-red">*</span> là bắt buộc nhập.</em></p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-default bg-font-default font-weight-bold" (click)="close()" [disabled]="saving"><em class="fas fa-times"></em> {{"Cancel" | localize}}</button>
                    <button type="submit" class="btn bg-green-ythd bg-font-green-ythd font-weight-bold" [disabled]="!mySettingsModalForm.form.valid || saving"><em class="fa fa-save font-white"></em> <span> Lưu thông tin</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal *ngIf="user" #smsVerificationModal (modalSave)="changePhoneNumberToVerified()" [newPhoneNumber]="user.phoneNumber"></smsVerificationModal>
