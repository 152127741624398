import {Input,
    Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';

@Component({
    selector: 'thang-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn tháng"
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ThangComboComponent),
        multi: true
    }]
})

export class ThangComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy) {
        super();
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        const key = 'thang-combo-data';
        const cache = sessionStorage.getItem(key);
        if (cache) {
            this.setListOfOption(JSON.parse(cache));
            return;
        }
        let arrThang = [1,2,3,4,5,6,7,8,9,10,11,12];
        let lst = _.map(arrThang, (it) => {
            return Object.assign({}, {
                value: it,
                displayText: 'Tháng '+it,
                fts: AppUtilityService.getFullTextSearch('Tháng '+it)
            });
        });
        sessionStorage.setItem(key, JSON.stringify(lst));
        this.setListOfOption(lst);

    }

}
