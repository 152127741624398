import {Input,
    Component, forwardRef, OnInit, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TheoDoiThuocTaiTruongServiceProxy, UtilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '@main_pages/_base/services/app-utility.service';
import { BaseComboBoxComponent } from '@main_pages/_base/form-custom/combo-box/base-combo-box.component';
import * as _ from 'lodash';
import { Output } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'thuoc-tai-truong-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear
                   nzPlaceHolder="Chọn ..."
                   [nzMode]="nzMode"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ThuocTaiTruongComboComponent),
        multi: true
    }]
})

export class ThuocTaiTruongComboComponent extends BaseComboBoxComponent implements OnInit, ControlValueAccessor {
    constructor(private _service: UtilityServiceProxy,
        private _thuocTaiTruongService: TheoDoiThuocTaiTruongServiceProxy,
        private appSession: AppSessionService) {
        super();
    }

    @Output() getInfoThuoc = new EventEmitter();


    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    onChangeValue(v) {
        let a = this.optionList.find(x => x.value === v);
        this.getInfoThuoc.emit(a);
        this.onChange(v);
    }

    getDataSourceFromServer() {
        this._thuocTaiTruongService.getDanhSachThuocTaiTruong().subscribe((result) => {
            if(result){
                let lst = _.map(result, (it) => {
                    return Object.assign({}, {
                        value: it.thuocId,
                        displayText: it.tenThuoc,
                        fts: AppUtilityService.getFullTextSearch(it.tenThuoc)
                    });
                });
                this.setListOfOption(lst);
            }
        });
    }
}
